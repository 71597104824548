import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hide_menu_toggle: boolean;
  menu_toggle:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GuestNavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      // Customizable Area Start
      hide_menu_toggle: false,
      menu_toggle:false
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onClickMenyToggle = () => {
    this.setState({ menu_toggle: true })
  }
  onClickMenyToggleOff = () => {
    this.setState({ menu_toggle: false })
  }

  handleCollape = () => {
    this.setState({ hide_menu_toggle: !this.state.hide_menu_toggle })
  }

  onClickRedirectHomePage = (name:string) => {
    this.props.navigation.navigate(name)
  }
  
  // Customizable Area End
}
