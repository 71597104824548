import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  isLoading:boolean;
  allCourseList:any;
  serchCourseValue:string;
  categoriesDataMyCourse: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyCourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseListApiCallId:any;
  getCategoriesApiCallIdMyCourse: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      isLoading:false,
      allCourseList:[],
      serchCourseValue:"",
      categoriesDataMyCourse: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData("token") || ""
    this.setState({ authToken: authToken }, () => {
      this.getAllCourseList()
      this.getCategoriesMyCourse();
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getAllCourseListResponce(apiRequestCallId, responseJson)
      if (apiRequestCallId === this.getCategoriesApiCallIdMyCourse && responseJson.data ) {
        this.setState({categoriesDataMyCourse: responseJson.data});
      }
    }
    // Customizable Area End
  }

  getAllCourseListResponce = (apiRequestCallId:any, responseJson:any) => {
    apiRequestCallId === this.getCourseListApiCallId && responseJson && this.getResponceListData(responseJson)
  }

  getResponceListData = (responseJson:any) => {
    this.setState({isLoading:false})
    let arrayOfObjects = responseJson?.data?.sort((a:any, b:any) => b.id - a.id);
    this.setState({allCourseList:arrayOfObjects || [], isLoading:false})
  }
  // Customizable Area End

  // Ctomizable Area Start
  onClickRedirect = () => {
    this.props.navigation.navigate("CourseCreate")
  }

  getAllCourseList = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listAllCoursesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  onClickEditCourse = (ids:string) => {
    this.props.navigation.navigate("EditCourse",{id:ids})
  }

  onClickOpenAnalyticsCourse = (ids:string) => {
    localStorage.setItem('sessionReportingId', JSON.stringify(Number(ids)))
    this.props.navigation.navigate("VisualAnalyticsChart");
  }

  serchCourseList = (event:any) => {
    this.setState({serchCourseValue: event.target.value})
  }

  convertMinutesMyCourse = (minutesOfCourse: number) => {
    const hourConvertCourse = Math.floor(minutesOfCourse / 60);
    const remainingMinutesConvertCourse = minutesOfCourse % 60 === 0 ? '' : `and ${minutesOfCourse % 60} minutes`;
    return `${hourConvertCourse} hours ${remainingMinutesConvertCourse}`;
  }

  getCourseCategoryMyCourse = (categoryIdMyCourse: number) => {
    let categoryNameMyCourse = this.state.categoriesDataMyCourse.map((item: any, index: number) => {
      if (Number(item.id) === categoryIdMyCourse) {
        return item.attributes.name;
      }
    });
  
    return categoryNameMyCourse;
  };

  getCategoriesMyCourse = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallIdMyCourse = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryMyCourseAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
