// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { Toaster } from "react-hot-toast"

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Breadcrumbnavigation from "../../blocks/breadcrumbnavigation/src/Breadcrumbnavigation";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform from "../../blocks/customform/src/Customform";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import {Emailnotifications2Styles} from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Assessmenttest from "../../blocks/assessmenttest/src/Assessmenttest.web";
import AssessmenttestResult from "../../blocks/assessmenttest/src/AssessmenttestResult.web";
import AssessmenttestMarks from "../../blocks/assessmenttest/src/AssessmenttestMarks.web"
import AssessmenttestList from "../../blocks/assessmenttest/src/AssessmenttestList.web";
import AssessmenttestCreate from "../../blocks/assessmenttest/src/AssessmenttestCreate.web"
import AssessmenttestEdit from "../../blocks/assessmenttest/src/AssessmenttestEdit.web"
import Assessment from "../../blocks/assessmenttest/src/Assessment.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import VisualAnalyticsChart from "../../blocks/visualanalytics/src/VisualAnalyticsChart";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Download from "../../blocks/download/src/Download";
import Location from "../../blocks/location/src/Location";
import Questionbank from "../../blocks/questionbank/src/Questionbank";
import Formapprovalworkflow from "../../blocks/formapprovalworkflow/src/Formapprovalworkflow";
import Dataencryption from "../../blocks/dataencryption/src/Dataencryption";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Dashboardguests from "../../blocks/dashboardguests/src/Dashboardguests";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CreateCourse from "../../blocks/customform/src/CreateCourse.web";
import EditCourse from "../../blocks/customform/src/EditCourse.web";
import Accountscoreranking from "../../blocks/accountscoreranking/src/Accountscoreranking";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/StudentContactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Cftranscription2 from "../../blocks/cftranscription2/src/Cftranscription2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import Videos from "../../blocks/videos/src/Videos";


import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/PasswordReset.web";

import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/CreateNewPassword.web";
import PasswordChange from "../../blocks/forgot-password/src/PasswordChange.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Payments from "../../blocks/stripepayments/src/Payments.web";
import GetPayments from "../../blocks/stripepayments/src/GetPayments.web";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PrivacyPolicy from "../../blocks/contentmanagement3/src/PrivacyPolicy.web";
import {TermsConditionsStyles} from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Searchhistory from "../../blocks/searchhistory/src/Searchhistory";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web.tsx";
// import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import FashionDesign from "../../blocks/categoriessubcategories/src/FashionDesign.web";
import SearchScreenContent from "../../blocks/categoriessubcategories/src/SearchScreenContent.web";
import Maps from "../../blocks/maps/src/Maps";

// Import Routes
// import EmailAccountRegistrationMain from "../../blocks/email-account-login/src/EmailAccountLoginMain.web";
import EmailAccountLoginMain from "../../blocks/email-account-login/src/EmailAccountLoginMain.web";
import EmailAccountLoginBlockWEB from "../../blocks/email-account-login/src/EmailLoginBlock.web";
import SignUp from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import {LecturerSignUpStyles} from "../../blocks/email-account-registration/src/LecturerSignUp.web"
import SignUpVerifyNumber from "../../blocks/email-account-registration/src/EmailAccountVerifyNumber.web";
import ViewUserProfile from "../../blocks/customisableuserprofiles2/src/EditUserProfile.web"
import EditLecturerUserProfile from "../../blocks/customisableuserprofiles2/src/EditLecturerUserProfile.web"
import EditUserProfile from "../../blocks/customisableuserprofiles2/src/EditUserProfile.web"
import MyCourses from "../../blocks/dashboard/src/MyCourses.web"
import MyCourse from "../../blocks/customform/src/MyCourse.web"
import AboutRaffles from "../../blocks/termsconditions/src/AboutRaffles.web"
import ChangePassword from "../../blocks/customisableuserprofiles2/src/ChangePassword.web"
import LecturerLiveSessions from "../../blocks/scheduling/src/LecturerLiveSessions.web"
import LecturerLiveSessionsCreate from "../../blocks/scheduling/src/LecturerLiveSessionsCreate.web"
import LecturerLiveSessionsEdit from "../../blocks/scheduling/src/LecturerLiveSessionsEdit.web"
import GuestContactus from "../../blocks/contactus/src/GuestContactus.web"
import GuestAboutRaffles from "../../blocks/termsconditions/src/GuestAboutRaffles.web"

const routeMap = {
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: "/Customisableuserprofiles2"
  },
  GuestContactus: {
    component: GuestContactus,
    path: "/GuestContactus"
  },
  GuestAboutus: {
    component: GuestAboutRaffles,
    path: "/GuestAboutus"
  },
  GuestCourse: {
    component: Categoriessubcategories,
    path: "/GuestCourse"
  },
  Surveys2: {
    component: Surveys2,
    path: "/Surveys2"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  Breadcrumbnavigation: {
    component: Breadcrumbnavigation,
    path: "/Breadcrumbnavigation"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Emailnotifications2: {
    component: Emailnotifications2Styles,
    path: "/Emailnotifications2"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  Assessmenttest: {
    component: Assessmenttest,
    path: "/Assessmenttest/:id"
  },
  AssessmenttestResult: {
    component: AssessmenttestResult,
    path: "/AssessmenttestResult"
  },
  AssessmenttestMarks: {
    component: AssessmenttestMarks,
    path: "/AssessmenttestMarks"
  },
  AssessmenttestList: {
    component: AssessmenttestList,
    path: "/AssessmenttestList"
  },
  AssessmenttestCreate: {
    component: AssessmenttestCreate,
    path: "/AssessmenttestCreate"
  },
  AssessmenttestEdit: {
    component: AssessmenttestEdit,
    path: "/AssessmenttestEdit/:id"
  },
  AssessmentRetakeTest: {
    component: Assessmenttest,
    path: "/retakeAssessmenttest/:id"
  },
  Assessment: {
    component: Assessment,
    path: "/Assessment"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  VisualAnalyticsChart: {
    component: VisualAnalyticsChart,
    path: "/VisualAnalyticsChart"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Questionbank: {
    component: Questionbank,
    path: "/Questionbank"
  },
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: "/Formapprovalworkflow"
  },
  Dataencryption: {
    component: Dataencryption,
    path: "/Dataencryption"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Dashboardguests: {
    component: Dashboardguests,
    path: "/Dashboardguests"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  CourseCreate: {
    component: CreateCourse,
    path: "/CourseCreate"
  },
  EditCourse: {
    component: EditCourse,
    path: "/EditCourse/:id"
  },
  Accountscoreranking: {
    component: Accountscoreranking,
    path: "/Accountscoreranking"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  LecturerLiveSessions: {
    component: LecturerLiveSessions,
    path: "/live-sessions"
  },
  LecturerLiveSessionsCreate: {
    component: LecturerLiveSessionsCreate,
    path: "/live-sessions-create"
  },
  LecturerLiveSessionsEdit :{
    component: LecturerLiveSessionsEdit,
    path: "/live-sessions-edit/:id"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Cftranscription2: {
    component: Cftranscription2,
    path: "/Cftranscription2"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLogin"
  },
  EmailAccountLoginBlockWeb: {
    component: EmailAccountLoginBlockWEB,
    path: "/Signin"
  },
  Automaticreminders: {
    component: Automaticreminders,
    path: "/Automaticreminders"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  PasswordChange: {
    component: PasswordChange,
    path: "/PasswordChange"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ChatView: {
    component: ChatView,
    path: "/ChatView"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  Payments: {
    component: Payments,
    path: "/Payments/:id"
  },
  GetPayments: {
    component: GetPayments,
    path: "/GetPayments"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  TermsConditions: {
    component: TermsConditionsStyles,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Searchhistory: {
    component: Searchhistory,
    path: "/Searchhistory"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  LandingPage: {
    component: LandingPage,
    path: "/",
    exact: true
  },
  // EmailAccountRegistrationSignin:{
  //  component:EmailAccountRegistrationSignin,
  // path:"/EmailAccountRegistration"},
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: "/Interactivefaqs"
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: "/AddInteractivefaqs"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  FashionDesign: {
    component: FashionDesign,
    path: "/FashionDesign/:id"
  },
  Searching: {
    component: SearchScreenContent,
    path: "/Searching"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },

  Home: {
    component: EmailAccountLoginMain,
    path: '/Selectrole',
  },


  Signup: {
    component: SignUp,
    path: '/Signup',
    exact: true
  },
  LecturerSignup: {
    component: LecturerSignUpStyles,
    path: '/LecturerSignup',
    exact: true
  },

  SignUpVerifyNumber: {
    component: SignUpVerifyNumber,
    path: '/SignupVerifuNumber',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // ViewUserProfile: {
  //   component: ViewUserProfile,
  //   path: '/ViewUserProfile'
  // },

  ViewUserProfile: {
    component: ViewUserProfile,
    path: '/ViewUserProfile'
  },
  UserProfile: {
    component: EditLecturerUserProfile,
    path: '/UserProfile'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  MyCourses: {
    component: MyCourses,
    path: "/MyCourses",
  },

  MyCoursesLecturer: {
    component: MyCourse,
    path: "/MyCoursesLecturer",
  },

  AboutRaffles: {
    component: AboutRaffles,
    path: "/AboutRaffles",
  },

  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword",
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <Toaster />
      </View>
    );
  }
}

export default App;
