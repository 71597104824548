import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import { Box, Grid, Button } from "@material-ui/core";
import { DirectionsRenderer, GoogleMap, Marker } from "react-google-maps";
import withGoogleMap from "react-google-maps/lib/withGoogleMap";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import Paper from '@material-ui/core/Paper';
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

// Customizable Area Start
interface MapComponentProps {
  googleMapURL: string;
  loadingElement: JSX.Element;
  containerElement: JSX.Element;
  mapElement: JSX.Element;
}
// istanbul ignore next
const MapComponent = withScriptjs(
  withGoogleMap(({ center, directions, currentLocationCoordinates }: { center: { lat: number; lng: number }, directions: {} | null, currentLocationCoordinates: { lat: number; lng: number } | null }) => (
    <GoogleMap defaultZoom={10} center={center}>
      {directions && <DirectionsRenderer directions={directions} />}
      {currentLocationCoordinates && (
        <Marker
          position={currentLocationCoordinates}
          title="Current Location"
        />
      )}
    </GoogleMap>
  ))
);
// Customizable Area End
import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Grid container>
        <Grid item xs={12} md={4} style={sideBarPad}>
          <Grid container style={sideBarCon}>

            <Grid item>
              <Box style={iconsWrapper}>
                <Box style={iconsCommon}>
                  <Button onClick={() => this.handleTravelModeChange("DRIVING")} data-test-id="DRIVING">
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item>
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.90135 5.99935V10.166H13.568V5.99935H1.90135ZM4.8701 8.9681C4.62704 9.21115 4.3319 9.33268 3.98468 9.33268C3.63746 9.33268 3.34232 9.21115 3.09926 8.9681C2.85621 8.72504 2.73468 8.4299 2.73468 8.08268C2.73468 7.73546 2.85621 7.44032 3.09926 7.19727C3.34232 6.95421 3.63746 6.83268 3.98468 6.83268C4.3319 6.83268 4.62704 6.95421 4.8701 7.19727C5.11315 7.44032 5.23468 7.73546 5.23468 8.08268C5.23468 8.4299 5.11315 8.72504 4.8701 8.9681ZM12.3701 8.9681C12.127 9.21115 11.8319 9.33268 11.4847 9.33268C11.1375 9.33268 10.8423 9.21115 10.5993 8.9681C10.3562 8.72504 10.2347 8.4299 10.2347 8.08268C10.2347 7.73546 10.3562 7.44032 10.5993 7.19727C10.8423 6.95421 11.1375 6.83268 11.4847 6.83268C11.8319 6.83268 12.127 6.95421 12.3701 7.19727C12.6132 7.44032 12.7347 7.73546 12.7347 8.08268C12.7347 8.4299 12.6132 8.72504 12.3701 8.9681Z"
                            fill="#1A73E8"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.73468 11.8327V12.666C2.73468 12.9021 2.65482 13.1 2.4951 13.2598C2.33537 13.4195 2.13746 13.4993 1.90135 13.4993H1.06801C0.831902 13.4993 0.633986 13.4195 0.474264 13.2598C0.314541 13.1 0.23468 12.9021 0.23468 12.666V5.99935L1.98468 0.999349C2.06801 0.749349 2.21732 0.54796 2.4326 0.395182C2.64787 0.242405 2.88746 0.166016 3.15135 0.166016H12.318C12.5819 0.166016 12.8215 0.242405 13.0368 0.395182C13.252 0.54796 13.4013 0.749349 13.4847 0.999349L15.2347 5.99935V12.666C15.2347 12.9021 15.1548 13.1 14.9951 13.2598C14.8354 13.4195 14.6375 13.4993 14.4013 13.4993H13.568C13.3319 13.4993 13.134 13.4195 12.9743 13.2598C12.8145 13.1 12.7347 12.9021 12.7347 12.666V11.8327H2.73468ZM2.56801 4.33268H12.9013L12.0263 1.83268H3.44301L2.56801 4.33268ZM1.90135 10.166H13.568V5.99935H1.90135V10.166Z"
                            fill="#1A73E8"
                          />
                        </svg>
                      </Grid>
                      <Grid item>{this.state.travelMode === "DRIVING" && this.state.travelDuration}
                      </Grid>
                    </Grid>
                  </Button>
                </Box>
                <Box style={iconsCommon}>
                  <Button onClick={() => this.handleTravelModeChange("TRANSIT")} data-test-id="TRANSIT">
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item>
                        <svg
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.73472 16.4993V15.666L2.98472 14.8327C2.16528 14.8327 1.4743 14.5514 0.911804 13.9889C0.349304 13.4264 0.0680542 12.7355 0.0680542 11.916V3.99935C0.0680542 2.84657 0.602776 2.00282 1.67222 1.4681C2.74167 0.933377 4.42917 0.666016 6.73472 0.666016C9.12361 0.666016 10.8319 0.92296 11.8597 1.43685C12.8875 1.95074 13.4014 2.8049 13.4014 3.99935V11.916C13.4014 12.7355 13.1201 13.4264 12.5576 13.9889C11.9951 14.5514 11.3042 14.8327 10.4847 14.8327L11.7347 15.666V16.4993H1.73472ZM1.73472 7.33268H5.90139V4.83268H1.73472V7.33268ZM7.56805 7.33268H11.7347V4.83268H7.56805V7.33268ZM3.81805 12.3327C4.16528 12.3327 4.46042 12.2112 4.70347 11.9681C4.94653 11.725 5.06805 11.4299 5.06805 11.0827C5.06805 10.7355 4.94653 10.4403 4.70347 10.1973C4.46042 9.95421 4.16528 9.83268 3.81805 9.83268C3.47083 9.83268 3.17569 9.95421 2.93264 10.1973C2.68958 10.4403 2.56805 10.7355 2.56805 11.0827C2.56805 11.4299 2.68958 11.725 2.93264 11.9681C3.17569 12.2112 3.47083 12.3327 3.81805 12.3327ZM9.65139 12.3327C9.99861 12.3327 10.2937 12.2112 10.5368 11.9681C10.7799 11.725 10.9014 11.4299 10.9014 11.0827C10.9014 10.7355 10.7799 10.4403 10.5368 10.1973C10.2937 9.95421 9.99861 9.83268 9.65139 9.83268C9.30416 9.83268 9.00903 9.95421 8.76597 10.1973C8.52291 10.4403 8.40139 10.7355 8.40139 11.0827C8.40139 11.4299 8.52291 11.725 8.76597 11.9681C9.00903 12.2112 9.30416 12.3327 9.65139 12.3327Z"
                            fill="#151515"
                          />
                        </svg>
                      </Grid>
                      <Grid item>{this.state.travelMode === "TRANSIT" && this.state.travelDuration}</Grid>
                    </Grid>
                  </Button>
                </Box>
                <Box style={iconsCommon}>
                  <Button onClick={() => this.handleTravelModeChange("WALKING")} data-test-id="WALKING">
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item>
                        <svg
                          width="12"
                          height="19"
                          viewBox="0 0 12 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.56801 18.1667L3.90135 6.41667L2.40135 7V9.83333H0.73468V5.91667L4.94301 4.125C5.13746 4.04167 5.34232 3.99306 5.5576 3.97917C5.77287 3.96528 5.97774 3.99306 6.17218 4.0625C6.36662 4.13194 6.55065 4.22917 6.72426 4.35417C6.89787 4.47917 7.04024 4.63889 7.15135 4.83333L7.98468 6.16667C8.34579 6.75 8.83538 7.22917 9.45343 7.60417C10.0715 7.97917 10.7763 8.16667 11.568 8.16667V9.83333C10.5958 9.83333 9.72774 9.63194 8.96385 9.22917C8.19996 8.82639 7.54718 8.3125 7.00551 7.6875L6.48468 10.25L8.23468 11.9167V18.1667H6.56801V12.75L4.81801 11.4167L3.31801 18.1667H1.56801ZM6.98468 3.58333C6.52635 3.58333 6.13399 3.42014 5.8076 3.09375C5.48121 2.76736 5.31801 2.375 5.31801 1.91667C5.31801 1.45833 5.48121 1.06597 5.8076 0.739583C6.13399 0.413194 6.52635 0.25 6.98468 0.25C7.44301 0.25 7.83538 0.413194 8.16176 0.739583C8.48815 1.06597 8.65135 1.45833 8.65135 1.91667C8.65135 2.375 8.48815 2.76736 8.16176 3.09375C7.83538 3.42014 7.44301 3.58333 6.98468 3.58333Z"
                            fill="#151515"
                          />
                        </svg>
                      </Grid>
                      <Grid item>{this.state.travelMode === "WALKING" && this.state.travelDuration}</Grid>
                    </Grid>
                  </Button>
                </Box>
              </Box>

              <Box>
                <Grid container>

                  <Grid item xs={2} style={sideBar1}>

                    <Grid container alignItems="center" style={sideBar2}>
                      <Grid item>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d_9357_3231)">
                            <rect
                              x="4"
                              y="3"
                              width="17"
                              height="17"
                              rx="8.5"
                              fill="#1A73E8"
                            />
                            <rect
                              x="5"
                              y="4"
                              width="15"
                              height="15"
                              rx="7.5"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_9357_3231"
                              x="0"
                              y="0"
                              width="25"
                              height="25"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="1" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_9357_3231"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_9357_3231"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </Grid>
                      <Grid item>
                        <svg
                          width="2"
                          height="19"
                          viewBox="0 0 2 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L1 18"
                            stroke="#585858"
                            strokeWidth="2"
                            strokeLinecap="round"
                            stroke-dasharray="1 4"
                          />
                        </svg>
                      </Grid>
                      <Grid item>
                        <svg
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.73472 8.49935C7.19305 8.49935 7.58542 8.33615 7.9118 8.00977C8.23819 7.68338 8.40139 7.29102 8.40139 6.83268C8.40139 6.37435 8.23819 5.98199 7.9118 5.6556C7.58542 5.32921 7.19305 5.16602 6.73472 5.16602C6.27639 5.16602 5.88403 5.32921 5.55764 5.6556C5.23125 5.98199 5.06805 6.37435 5.06805 6.83268C5.06805 7.29102 5.23125 7.68338 5.55764 8.00977C5.88403 8.33615 6.27639 8.49935 6.73472 8.49935ZM6.73472 14.6243C8.42916 13.0688 9.68611 11.6556 10.5056 10.3848C11.325 9.11393 11.7347 7.98546 11.7347 6.99935C11.7347 5.48546 11.2521 4.24588 10.2868 3.2806C9.32153 2.31532 8.1375 1.83268 6.73472 1.83268C5.33194 1.83268 4.14792 2.31532 3.18264 3.2806C2.21736 4.24588 1.73472 5.48546 1.73472 6.99935C1.73472 7.98546 2.14444 9.11393 2.96389 10.3848C3.78333 11.6556 5.04028 13.0688 6.73472 14.6243ZM6.73472 16.8327C4.49861 14.9299 2.82847 13.1625 1.7243 11.5306C0.620138 9.89865 0.0680542 8.38824 0.0680542 6.99935C0.0680542 4.91602 0.738193 3.25629 2.07847 2.02018C3.41875 0.784071 4.97083 0.166016 6.73472 0.166016C8.49861 0.166016 10.0507 0.784071 11.391 2.02018C12.7312 3.25629 13.4014 4.91602 13.4014 6.99935C13.4014 8.38824 12.8493 9.89865 11.7451 11.5306C10.641 13.1625 8.97083 14.9299 6.73472 16.8327Z"
                            fill="#F61818"
                          />
                        </svg>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={10}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item xs={10}>
                        <input
                          type="text"
                          value={this.state.currentLocation}
                          onChange={this.handleCurrentLocationChange}
                          data-test-id="txtInput"
                          style={mapFieldsCommon}
                          placeholder="Current Location"
                          onKeyDown={this.handleKeyDown}
                        />
                      </Grid>

                      <Grid item xs={2}
                        style={sideBarCommon}
                      >
                        <svg
                          width="16"
                          height="5"
                          viewBox="0 0 16 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 4.98633C1.45 4.98633 0.979167 4.79049 0.5875 4.39883C0.195833 4.00716 0 3.53633 0 2.98633C0 2.43633 0.195833 1.96549 0.5875 1.57383C0.979167 1.18216 1.45 0.986328 2 0.986328C2.55 0.986328 3.02083 1.18216 3.4125 1.57383C3.80417 1.96549 4 2.43633 4 2.98633C4 3.53633 3.80417 4.00716 3.4125 4.39883C3.02083 4.79049 2.55 4.98633 2 4.98633ZM8 4.98633C7.45 4.98633 6.97917 4.79049 6.5875 4.39883C6.19583 4.00716 6 3.53633 6 2.98633C6 2.43633 6.19583 1.96549 6.5875 1.57383C6.97917 1.18216 7.45 0.986328 8 0.986328C8.55 0.986328 9.02083 1.18216 9.4125 1.57383C9.80417 1.96549 10 2.43633 10 2.98633C10 3.53633 9.80417 4.00716 9.4125 4.39883C9.02083 4.79049 8.55 4.98633 8 4.98633ZM14 4.98633C13.45 4.98633 12.9792 4.79049 12.5875 4.39883C12.1958 4.00716 12 3.53633 12 2.98633C12 2.43633 12.1958 1.96549 12.5875 1.57383C12.9792 1.18216 13.45 0.986328 14 0.986328C14.55 0.986328 15.0208 1.18216 15.4125 1.57383C15.8042 1.96549 16 2.43633 16 2.98633C16 3.53633 15.8042 4.00716 15.4125 4.39883C15.0208 4.79049 14.55 4.98633 14 4.98633Z"
                            fill="black"
                          />
                        </svg>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                      <Grid item xs={10}>
                        <input
                          type="text"
                          value={this.state.destination}
                          onChange={this.handleDestinationChange}
                          data-test-id="txtInput1"
                          placeholder="Destination"
                          style={mapFieldsCommon}
                          onKeyDown={this.handleKeyDown}

                        />
                      </Grid>

                      <Grid item xs={2}
                        style={sideBarCommon}
                      >
                        <svg
                          width="17"
                          height="21"
                          viewBox="0 0 17 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6818 15.5L11.6818 20.5L6.68182 15.5L8.10682 14.1L10.6818 16.675L10.6818 9.5H12.6818L12.6818 16.675L15.2568 14.1L16.6818 15.5ZM10.6818 5.5L9.25682 6.9L6.68182 4.325L6.68182 11.5H4.68182L4.68182 4.325L2.10682 6.9L0.681824 5.5L5.68182 0.5L10.6818 5.5Z"
                            fill="black"
                          />
                        </svg>
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Box>
            </Grid>

          </Grid>

        </Grid>

        <Grid item xs={12} md={8} style={mapContainer}>
          <Box>
            <MapComponent
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC74_7QnkOgBJRb2SecLdMvVrDL57anZzw&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={mapsCommonn} />}
              containerElement={<div style={mapContainerStyle} />}
              mapElement={<div style={mapsCommonn} />}
              center={this.state.mapCenter}
              directions={this.state.directions}
              data-test-id="mapcomp"
              currentLocationCoordinates={this.state.currentLocationCoordinates}
            />
          </Box>
        </Grid>
      </Grid>

    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  map: {
    width: viewportWidth,
    height: viewportHeight,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});

const mapContainerStyle: React.CSSProperties = {
  height: '100vh',
};

const mapsCommonn: React.CSSProperties = { height: '100%' , borderTopRightRadius: "10px", borderBottomRightRadius: "10px" };
const sideBarPad: React.CSSProperties = { paddingTop: "20px", height: "100vh", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", borderLeft: "1px solid #94a3b8", borderTop: "1px solid #94a3b8", borderBottom: "1px solid #94a3b8" };
const mapContainer: React.CSSProperties = { height: "100vh", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", borderRight: "1px solid #94a3b8", borderTop: "1px solid #94a3b8", borderBottom: "1px solid #94a3b8" };
const sideBar1: React.CSSProperties = { marginBottom: "auto", marginTop: "auto"};
const sideBar2: React.CSSProperties = { flexDirection: "column" };
const sideBarCommon: React.CSSProperties = { textAlign: "center" };
const mapFieldsCommon: React.CSSProperties = { minHeight: "43px", padding: "12px 8px 12px 8px", border: "1px solid #D3D3D3", margin: "5px 0px 5px 0px" }
const iconsCommon: React.CSSProperties = { width: "33%", margin: 'auto' }
const iconsWrapper: React.CSSProperties = {
  display: "flex", justifyContent: "space-between"
}
const radius: React.CSSProperties = { borderRadius: "10px", width: "300px", height: "auto", display: "flex", justifyContent: "space-around", alignItems: "center", marginTop: '10px', marginBottom: "10px" }
const sideBarResult: React.CSSProperties = { width: "300px", height: "auto", marginTop: 160, display: "flex", justifyContent: "space-around", alignItems: "center", borderRadius: "10px" }
const sideBarCon: React.CSSProperties = { flexDirection: "column", alignItems: "center", height: "100vh" };
const sidebarBtn: React.CSSProperties = { marginTop: "10px", marginBottom: "10px" }
// Customizable Area End
