import React from 'react';
import OtpInput from 'react-otp-input';
import { logo } from './assets';
import EmailAccountRegistrationController, { Props } from './EmailAccountRegistrationController'
import { getStorageData } from '../../../framework/src/Utilities';

export default class EmailAccountVerifyNumber extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
  render() {
    let phone_number = getStorageData("contact_no")
    return (
        <>
        <div className="main-wrapper" data-test-id='testid'>
            <div className="signin-form-wrapper align-center" id='fakid'>
                <div className="col-6">
                    <div className="form-image faqclass">
                        <div className="login-image" data-test-id='secondfak'>
                            <img src={logo} alt="logo" width={300} />
                        </div>
                    </div>
                </div>
                <div className="col-6 signup-item">
                    <div className="close-btn" id='checkid' style={{marginTop: "-100px"}}>
                        <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </a>
                    </div>
                    <div className="login-form-wrapper fakehai">
                        <form>
                            <div className="block-heading">
                                <h4>Verify Mobile Number</h4>
                                <p>Enter the code just sent to: {this.state.fullContactNumber} </p>
                            </div>
                            <div className="block-content">
                                <div className="form-group otp-block">
                                    <label htmlFor="code">Enter Code</label>

                                    <OtpInput
                                        value={this.state.otp}
                                        inputType="tel"
                                        data-test-id="OtpField"
                                        shouldAutoFocus={true}
                                        onChange={(value:any) => this.setState({ otp: value})}
                                        numInputs={6}
                                        renderSeparator={<span></span>}
                                        renderInput={(props:any) => <input {...props} />}
                                        inputStyle={{
                                            width: "1em",
                                            textAlign: "center",
                                            marginRight: "12px",
                                            opacity: "1",
                                            fontSize: "24px",
                                            minWidth: "66px",
                                            height: "80px",
                                            borderRadius: "10px",
                                            border: "1px solid rgb(0, 0, 0, 0.2)",
                                            background: "rgb(255, 255, 255)",
                                        }} />

                                </div>
                                <span className='send-span'>Didn't get code?<a href="#">Send again</a></span>
                                <a href="#">Call me instead</a>
                                <div className="form-group">
                                    <button onClick={this.handleOtpVerify} data-test-id="handleOtpVerifyid" type="button" className="btn button  mt-35" >Verify mobile number</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
  }
}
