import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import LecturerLiveSessionsController, {
  Props, InitialValues, Course
} from "./LecturerLiveSessionsController";
import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, InputLabel, Paper, Switch, Typography, withStyles } from "@material-ui/core";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { downimage } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const validationSchema = Yup.object({
  date:Yup.date()
  .nullable()
  .required('Date is required.')
  .min(new Date().toISOString().split('T')[0], 'Date should be in the future.'),
  courseImage: Yup.string().required('Image is required.'),
  subject: Yup.string().required('Subject is required.'),
  startTime: Yup.string().required('Start time is required.'),
  course_id:Yup.string().required('Course is required.'),
  endTime: Yup.string()
      .required('End time is required.')
      .test('is-greater', 'End time must be greater than start time.', function (value) {
        const { startTime } = this.parent;
        return !startTime || !value || value > startTime;
  }),
  livelocation: Yup.string().required('Location is required.'),
});

// Customizable Area End

export default class LecturerLiveSessionsEdit extends LecturerLiveSessionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  DeleteModal = () => {
    return (
      <ModalStyleContainer
        maxWidth={"sm"}
        fullWidth
        open={this.state.onDeleteOpen}
      >
        <DialogContent>
          <ModalStyle>
            <Box className="first-container" data-test-id="edit-session-1">
              <Grid container justifyContent="flex-end" data-test-id="edit-session-2">
                <Grid item data-test-id="edit-session-3">
                  <Box className="close-button-session" id="edit-session-4" data-test-id="close-button-session" onClick={() => this.onClickCloseDelete()}>
                    <svg id="edit-session-5" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                      <mask id="mask0_7217_3205" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_7217_3205)">
                        <path d="M6.4 19.5L5 18.1L10.6 12.5L5 6.9L6.4 5.5L12 11.1L17.6 5.5L19 6.9L13.4 12.5L19 18.1L17.6 19.5L12 13.9L6.4 19.5Z" fill="#1C1B1F" />
                      </g>
                    </svg>
                  </Box>
                </Grid>
              </Grid>
              <Box id="edit-session-7">
                <Box className="Delete-icon-container" id="edit-session-8">
                  <svg id="edit-session-9" xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0226 4.06417L16.7917 4.83333H19.5001C20.0959 4.83333 20.5834 5.32083 20.5834 5.91667C20.5834 6.5125 20.0959 7 19.5001 7H6.50008C5.90425 7 5.41675 6.5125 5.41675 5.91667C5.41675 5.32083 5.90425 4.83333 6.50008 4.83333H9.20841L9.97758 4.06417C10.1726 3.86917 10.4542 3.75 10.7359 3.75H15.2642C15.5459 3.75 15.8276 3.86917 16.0226 4.06417ZM6.50008 21.0833C6.50008 22.275 7.47508 23.25 8.66675 23.25H17.3334C18.5251 23.25 19.5001 22.275 19.5001 21.0833V10.25C19.5001 9.05833 18.5251 8.08333 17.3334 8.08333H8.66675C7.47508 8.08333 6.50008 9.05833 6.50008 10.25V21.0833ZM9.75008 10.25H16.2501C16.8459 10.25 17.3334 10.7375 17.3334 11.3333V20C17.3334 20.5958 16.8459 21.0833 16.2501 21.0833H9.75008C9.15425 21.0833 8.66675 20.5958 8.66675 20V11.3333C8.66675 10.7375 9.15425 10.25 9.75008 10.25Z" fill="#37657F" />
                  </svg>
                </Box>
                <Typography id="edit-session-10" className="title-one">Session Delete</Typography>
                <Typography id="edit-session-11" className="title-two">Are you sure want to delete this Session.</Typography>
              </Box>
            </Box>
            <Box className="second-container" id="edit-session-12">
              <Button id="edit-session-13" type="button" data-test-id="cancel-delete-button" onClick={() => this.onClickCloseDelete()} className="cancel-delete-button">Cancel Delete</Button>
              <Button type="button" id="edit-session-14" disabled={this.state.submitFlag} data-test-id="delete-session-button" onClick={() => this.onDelete()} className="delete-session-button">Delete Session</Button>
            </Box>
          </ModalStyle>
        </DialogContent>
      </ModalStyleContainer>
    )

  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={"Schedule Live Sessions"}
        >
          <MainContainer>
            <Paper elevation={0} className="paper-container">
              {this.state.isLoading ? (
                <Box component={"div"} className="loader-container" data-test-id="assessment-test-loader">
                  <CircularProgress style={{ color: '#205e83' }} />
                </Box>
              ) : (
                <Formik
                  initialValues={{
                    courseImage: this.state.editSessionData.sessionImage || "",
                    subject: this.state.editSessionData.subject,
                    livelocation: this.state.editSessionData.liveLocationAddress,
                    date: this.state.editSessionData.date,
                    startTime: this.state.editSessionData.startTime,
                    endTime: this.state.editSessionData.endTime,
                    showtostudent: this.state.editSessionData.showToStudent,
                    course_id:this.state.editSessionData.course_id as string
                  }}
                  onSubmit={(values) => {
                    this.onEditSubmit(values)
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, setFieldValue, values }: FormikProps<InitialValues>) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Box className="container-main">
                          <Box className="marginbottom">
                            <Typography className="from-main-title">Schedule Session</Typography>
                            <Box className="from-first-container">
                              <Grid container className="from-first-container-grid">
                                <Grid item>
                                  <Box className="image-container-main">
                                    <Box className="image-container">
                                      {values.courseImage ? (
                                        <img src={((values.courseImage as File)?.name ? URL.createObjectURL(values.courseImage as Blob) : baseURL + values.courseImage) || this.state.editSessionData.sessionImage} />
                                      ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                          <path d="M8.33334 35C7.41667 35 6.63167 34.6733 5.97834 34.02C5.325 33.3667 4.99889 32.5822 5 31.6667V8.33334C5 7.41667 5.32667 6.63167 5.98 5.97834C6.63334 5.325 7.41778 4.99889 8.33334 5H31.6667C32.5833 5 33.3683 5.32667 34.0217 5.98C34.675 6.63334 35.0011 7.41778 35 8.33334V31.6667C35 32.5833 34.6733 33.3683 34.02 34.0217C33.3667 34.675 32.5822 35.0011 31.6667 35H8.33334ZM10 28.3333H30L23.75 20L18.75 26.6667L15 21.6667L10 28.3333Z" fill="white" />
                                        </svg>
                                      )}
                                    </Box>
                                    <Box>
                                      <Typography className="from-main-title-first">{values.subject ? values.subject : "Live session Modern Art and Ideas"}</Typography>
                                      <Typography className="from-main-title-second">By Raffles Biban</Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Box className="upload-icon-container">
                                    <input
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onFileChange(event, setFieldValue)}
                                      accept="image/*"
                                      type="file"
                                      name="courseImage"
                                      data-test-id="uploadField"
                                      title=""
                                      id="courseImage"
                                      className="courseImage"
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                      <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="#37657F" />
                                    </svg>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <ErrorMessage name="courseImage" component="div" className="error-message" />
                          </Box>


                          <Box className="marginbottom">
                            <Typography className="from-main-title">Basic Information</Typography>
                            <Box className="from-first-container">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <InputLabel className="main-input-label">Subject*</InputLabel>
                                  <Field className="input-fiels-create" type="text" id="subject" name="subject" placeholder="Type here" />
                                  <ErrorMessage name="subject" component="div" className="error-message" />
                                </Grid>

                                <Grid item xs={12}>
                                  <InputLabel className="main-input-label" data-test-id="Subject">Course*</InputLabel>
                                  <Box className="select-course-category">
                                    <Field data-test-id="select-course-category" id="select-course-category" as="select" name="course_id">
                                      <option className="text-capitalize" disabled value="">Select Course</option>
                                      {this.state.courseList.map((item: Course, index: number) => {
                                        return (<option key={`categorylistselect${index}`} className="text-capitalize" data-test-id={`categorylistselect${index}`} value={item.id}>{item.attributes.course_name}</option>);
                                      })}
                                    </Field>
                                  </Box>
                                  <ErrorMessage name="course_id" component="div" className="error-message" data-test-id="course_id" />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <InputLabel className="main-input-label">Date*</InputLabel>
                                  <Box className="position-reletive">
                                    <Field className="input-fiels-create" type="date" id="date" name="date" placeholder="20-08-1990" />
                                    <Field className="input-fiels-create-upper" type="date" id="date" name="date" placeholder="20-08-1990" />
                                  </Box>
                                  <ErrorMessage name="date" component="div" className="error-message" />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <InputLabel className="main-input-label">Time*</InputLabel>
                                  <Box className="time-field">
                                    <Box component={"div"} className="position-reletive w-100">
                                      <Field className="input-fiels-create" type="time" id="startTime" name="startTime" placeholder="Type here" />
                                      <Field className="input-fiels-create-upper-time" type="time" id="startTime" name="startTime" placeholder="Type here" />
                                    </Box>
                                    <Typography>to</Typography>
                                    <Box component={"div"} className="position-reletive w-100">
                                      <Field className="input-fiels-create" type="time" id="endTime" name="endTime" placeholder="Type here" />
                                      <Field className="input-fiels-create-upper-time" type="time" id="endTime" name="endTime" placeholder="Type here" />
                                    </Box>
                                  </Box>
                                  <Box className="time-field-error-message">
                                    <Box className="width-100">
                                      <ErrorMessage name="startTime" component="div" className="error-message" />
                                    </Box>
                                    <Box className="width-100">

                                      <ErrorMessage name="endTime" component="div" className="error-message" />
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={12}>
                                  <Box className="switch-main-contianer">
                                    <InputLabel className="main-input-label margin-0">Show Live Location Address</InputLabel>
                                    <Box className="switch-main-contant">
                                      <InputLabel className="main-input-label margin-0">Show to student</InputLabel>
                                      <AntSwitch
                                        checked={values.showtostudent}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('showtostudent', event.target.checked)}
                                        data-test-id="showtostudent-change"
                                      />
                                    </Box>
                                  </Box>
                                  <Field rows="4" className="input-fiels-create-textarea" cols="50" as="textarea" id="livelocation" name="livelocation" placeholder="Type here" />
                                  <ErrorMessage name="livelocation" component="div" className="error-message" />
                                </Grid>

                              </Grid>
                            </Box>
                          </Box>

                          <Box className="marginbottom">
                            <Grid container justifyContent="flex-end" spacing={3}>
                              <Grid item>
                                <Button type="button" className="delete-course-lesson-btn" data-test-id="delete-course-lesson-btn" onClick={() => this.onClickOpenDelete()}>
                                  Delete
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                  </svg>
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button type="button" className="back-course-lesson-btn" data-test-id="go-back-save-session" onClick={() => this.onClickGoBackRedirect()}>
                                  Back
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button type="submit" data-test-id="save-course-lesson-btn" className="save-course-lesson-btn" disabled={this.state.submitFlag}>
                                  Save Session
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </form>
                    )
                  }}

                </Formik>
              )}
              
              {this.DeleteModal()}
            </Paper>
          </MainContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .switch-main-contianer":{display:"flex", justifyContent:"space-between",alignItems:"center", marginBottom: 16},
  "& .switch-main-contant":{display:"flex", justifyContent:"space-between",alignItems:"center",gap:10},
  "& .time-field":{display:"flex", alignItems:"center", gap:6,"@media (max-width: 520px)": {flexDirection: 'column'}},
  "& .time-field-error-message":{display:"flex", alignItems:"center", gap:30, justifyContent:"space-between"},
  "& .width-100":{
    width:"100%"
  },
  "& .margin-0":{
    margin:0
  },
  "& .input-fiels-create-upper-time":{"@media (max-width: 1440px)":{width:'72%',},"@media (max-width: 1350px)":{width:'70%',},"@media (max-width: 1250px)":{width:'65%',},"@media (max-width: 1150px)":{width:'63%',},"@media (max-width: 1050px)":{width:'58%',},"@media (max-width: 950px)":{width:'76%',},"@media (max-width: 850px)":{width:'70%',},"@media (max-width: 800px)":{width:'66%',},"@media (max-width: 767px)":{width:'80%',},"@media (max-width: 667px)":{width:'75%',},"@media (max-width: 520px)":{width:'80%',},"@media (max-width: 410px)":{width:'75%',},"@media (max-width: 350px)":{width:'70%',},"@media (max-width: 325px)":{width:'65%',},"&::placeholder":{color:"#334155",fontFamily:"Corbel V2",},"&::-webkit-calendar-picker-indicator":{width:'100%',margin:0,background:"transparent",height:48},border:"1px solid #CBD5E1",background:"#FFFFFF",fontFamily:"Corbel V2",minWidth:"auto",position:"absolute",top:0,opacity:0,width:'83%',right:0,padding:0,},
  "& .input-fiels-create-upper":{"@media (max-width: 1440px)":{width:'80%',},"@media (max-width: 1350px)":{width:'78%',},"@media (max-width: 1250px)":{width:'75%',},"@media (max-width: 1150px)":{width:'73%',},"@media (max-width: 1050px)":{width:'69%',},"@media (max-width: 950px)":{width:'81%',},"@media (max-width: 850px)":{width:'77%',},"@media (max-width: 800px)":{width:'74%',},"@media (max-width: 767px)":{width:'84%',},"@media (max-width: 667px)":{width:'80%',},"@media (max-width: 520px)":{width:'73%',},"@media (max-width: 410px)":{width:'60%',},"@media (max-width: 350px)":{width:'50%',},"@media (max-width: 325px)":{width:'40%',},"&::placeholder":{color:"#334155",fontFamily:"Corbel V2",},"&::-webkit-calendar-picker-indicator":{width:'100%',margin:0,background:"transparent",height:43},border:"1px solid #CBD5E1",background:"#FFFFFF",fontFamily:"Corbel V2",minWidth:"auto",position:"absolute",top:0,opacity:0,width:'87%',right:0,padding:0,},
  "& .from-main-title": {
    fontFamily: "Corbel V2",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10,
    color: "#000",
  },
  "& .from-first-container": {
    borderRadius: 8,
    padding: 40,
    background: "#E2E8F0",
  },
  "& .from-first-container-grid": {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  "& .image-container-main": {
    alignItems: 'center',
    display: "flex",
  },
  "& .image-container": {
    overflow: 'hidden',
    background: "rgba(0, 0, 0, 0.10)",
    borderRadius: 8,
    marginRight: 16,
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .from-main-title-first": {
    marginBottom: 5,
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
  },
  "& .from-main-title-second": {
    fontWeight: 400,
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 14,
  },
  "& .upload-icon-container": {
    position: "relative",
    background: "#F8FAFC",
    padding: 10,
    borderRadius: 6,
  },
  "& .main-input-label": {
    color: "#334155",
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 400,
  },
  "& .input-fiels-create": {
    fontFamily: "Corbel V2",
    border: "1px solid #CBD5E1",
    background: "#FFFFFF",
    minWidth:"auto",
    "&::placeholder": {
      color: "#334155",
      fontFamily: "Corbel V2",
    }
  },
  "& .error-message": {
    fontFamily: "Corbel V2",
    color: "#FF0000",
  },
  "& .input-fiels-create-textarea": {
    resize: "none",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    border: "1px solid #CBD5E1",
    width: "100%",
    padding: 10,
    borderRadius: 8,
    "&::placeholder": {
      fontFamily: "Corbel V2",
      color: "#334155"
    },
    "&:focus": {
      outline: "none"
    }
  },
  "& .paper-container": {
    width: "100%",
    overflow: "hidden",
    "& form": {
      margin: 0,
    }
  },
  "& .container-main": {
    height: 'calc(100vh - 110px)',
    padding: '40px',
    background: '#F6F8FA',
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  "& .add-lesson-btn, .save-course-lesson-btn": {
    fontWeight: 700,
    background: "#37657F",
    color: "#fff",
    width: '180px',
    height: '50px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    borderRadius:8,
    "& span": {
      fontSize: 16,
    },
    "& svg": {
      marginLeft: 6,
    },
  },
  "& .back-course-lesson-btn": {
    border: "1px solid #37657F",
    background: "transparent",
    color: "#37657F",
    width: '180px',
    height: '50px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    borderRadius:8,
    "& span": {
      fontSize: 16,
    }
  },
  "& .delete-course-lesson-btn":{
    border: "1px solid #F87171",
    background: "transparent",
    color: "#DC2626",
    width: '180px',
    height: '50px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    borderRadius:8,
    "& span": {
      fontSize: 16,
    },
    "& svg": {
      marginLeft: 6,
    },
  },
  "& .select-course-category":{
    background:"#FFFFFF",
    borderRadius: 8
  },
  "& #select-course-category":{
    appearance: "none",
    background: `url("${downimage}") no-repeat 99% center`
  },
  "& .courseImage": {
    opacity: 0,
    maxWidth: 37,
    minWidth: 37,
    minHeight: 37,
    maxHeight: 37,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  "& .marginbottom":{
    marginBottom:20
  },
  "& .position-reletive":{
    position:"relative"
  },
  "& .w-100":{
    width:"100%"
  },
  "& .loader-container":{overflowY:'auto',height:'calc(100vh - 240px)',display:'flex',alignItems:'center',justifyContent:'center',background:'#F6F8FA',},
})

const AntSwitch = withStyles({
  switchBase: {
    color: "#fff",
    padding: 2,
    '&$checked': {
      color: "#fff",
      transform: 'translateX(10px)',
      '& + $track': {
        borderColor: "transparent",
        opacity: 1,
        backgroundColor: "#37657F",
      },
    },
  },
  thumb: {
    boxShadow: 'none',
    width: 6,
    height: 6,
  },
  track: {
    backgroundColor: "#94A3B8",
    border: "1px solid transparent",
    borderRadius: 16 / 2,
    opacity: 1,
  },
  checked: {},
  root: {
    display: 'flex',
    width: 20,
    height: 10,
    padding: 0,
  },
})(Switch);

const ModalStyleContainer = styled(Dialog)({
  "& .MuiDialogContent-root, .MuiDialogContent-root:first-child":{
    padding:0
  }
})

const ModalStyle = styled(Box)({
  "& .close-button-session":{
    cursor:"pointer",
    width: '40px',
    height: '40px',
    borderRadius: '6px',
    background: 'rgba(217, 217, 217, 0.50)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  "& .first-container":{
    padding: 40,
  },
  "& .Delete-icon-container":{
    width: '56px',
    height: '56px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: '#F1F5F9',
    margin:"24px 0",
    display: 'flex',
  },
  "& .second-container":{
    borderTop: '1px solid #E2E8F0',
    display:'flex',
    gap:24,
    justifyContent:"flex-end",
    padding: '24px 40px',
  },
  "& .cancel-delete-button": {
    border: '1px solid #37657F',
    background: "#F1F5F9",
    color: "#37657F",
    width: '164px',
    height: '56px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight: 700,
    borderRadius: '8px',
    "& span": {
      fontSize: 16,
    },
    "& svg": {
      marginLeft: 6,
    },
  },
  "& .delete-session-button": {
    borderRadius: '8px',
    background: "#DC2626",
    color: "#FFF",
    width: '164px',
    height: '56px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight: 700,
    "& span": {
      fontSize: 16,
    },
    "& svg": {
      marginLeft: 6,
    },
  },
  "& .MuiTypography-root":{
    fontFamily: "Corbel V2",
  },
  "& .title-one":{
    fontWeight: 700,
    color: '#37657F',
    fontSize: '24px',
  },
  "& .title-two":{
    fontWeight: 400,
    color: '#37657F',
    fontSize: '16px',
  }
})
// Customizable Area End