import React from 'react';
import {
  Paper,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/ViewUserProfile.css";
import Customisableuserprofiles2Controller, { Props } from './Customisableuserprofiles2Controller';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const validationSchema = Yup.object({
  password: Yup.string().required('Old password is required.'),
  newpass: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .required('New password is required.'),
    confirmpass: Yup.string()
    .oneOf([Yup.ref('newpass'), null], 'Passwords must match.')
    .required('Confirm password is required.'),
});


export default class ChangePassword extends Customisableuserprofiles2Controller {

  constructor(props: Props) {
    super(props);
  }

  deleteModalVisible = () => {
    return (
      <Dialog maxWidth={'xs'} fullWidth aria-labelledby="simple-dialog-title" open={this.state.passwordSccuess}>
        <DialogTitle id="simple-dialog-title" className='modal-header'> 
        <button data-test-id="close_button_modal" onClick={() => this.props.navigation.goBack()} type="button" className="close">
               <span>×</span>
             </button></DialogTitle>
             <DialogContent className='dialog-content-body'>
             <div className="modal-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.0003 2.16602C7.02033 2.16602 2.16699 7.01935 2.16699 12.9993C2.16699 18.9793 7.02033 23.8327 13.0003 23.8327C18.9803 23.8327 23.8337 18.9793 23.8337 12.9993C23.8337 7.01935 18.9803 2.16602 13.0003 2.16602ZM13.0003 21.666C8.22283 21.666 4.33366 17.7768 4.33366 12.9993C4.33366 8.22185 8.22283 4.33268 13.0003 4.33268C17.7778 4.33268 21.667 8.22185 21.667 12.9993C21.667 17.7768 17.7778 21.666 13.0003 21.666ZM10.8337 15.3502L17.2037 8.98018C17.6262 8.55768 18.3195 8.55768 18.742 8.98018C19.1645 9.40268 19.1645 10.0852 18.742 10.5077L11.6028 17.6468C11.1803 18.0693 10.4978 18.0693 10.0753 17.6468L7.26949 14.841C6.84699 14.4185 6.84699 13.736 7.26949 13.3135C7.47189 13.1107 7.74668 12.9967 8.03324 12.9967C8.3198 12.9967 8.59459 13.1107 8.79699 13.3135L10.8337 15.3502Z" fill="#37657F"/>
                </svg>
             </div>
             <h5>Password Changed</h5>
             <p>Your password has been successfully Changed.</p>
           </DialogContent>
      </Dialog>
    )
  }

  edituserForm = () => {
    return <Formik
    initialValues={{
      password: this.state.changePassword.password,
      newpass:this.state.changePassword.newpass,
      confirmpass:this.state.changePassword.confirmpass
    }}
    data-test-id="submit-button"
    onSubmit={(values) => {
      this.editProfileDetailsPassword(values);
    }}
    validationSchema={validationSchema}
  >
    {({ handleSubmit,values }) => {
      return <form onSubmit={handleSubmit}>
        <div className="profile-block-content">
          <div className="profile-form-block">
            <Grid container spacing={3} justifyContent='flex-end'>
              <Grid item xs={12} md={6}>
                
                      <label htmlFor="password">Old Password*</label>
                      <div className='position-relative'>
                      <Field type={`${this.state.passwordVisible ? "text" : "password"}`} id="password" placeholder=" ********* " name="password" />
                      <span className="c-password">
                        <IconButton data-test-id="passwordVisible-btn" onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}>
                          {this.state.passwordVisible ? (
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                          ):(
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
                          )}
                          
                        </IconButton>
                      </span>
                      </div>
                      <span className='error-text p-0'>
                        <ErrorMessage name="password" component="div" className="error p-0" />
                      </span>
                  
              </Grid>
              <Grid item xs={12} md={6}>
                      <label htmlFor="newpass">New Password*</label>
                      <div className='position-relative'>
                      <Field type={`${this.state.newpassVisiblefals ? "text" : "password"}`} id="newpass" placeholder=" ********* " name="newpass" />
                      <span className="c-password">
                        <IconButton data-test-id="newpassVisiblefals-btn" onClick={() => this.setState({ newpassVisiblefals: !this.state.newpassVisiblefals })}>
                          {this.state.newpassVisiblefals ? (
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                          ):(
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
                          )}
                        </IconButton>
                      </span>
                      </div>
                      <span className='error-text p-0'>
                        <ErrorMessage name="newpass" component="div" className="error p-0" />
                      </span>
              </Grid>
              <Grid item xs={12} md={6}>
                <label htmlFor="confirmpass">Confirm Password*</label>
                      <div className='position-relative'>
                      <Field type={`${this.state.confirmpassVisible ? "text" : "password"}`} id="confirmpass" placeholder=" ********* " name="confirmpass" />
                      <span className="c-password">
                        <IconButton data-test-id="confirmpassVisible-btn" onClick={() => this.setState({ confirmpassVisible: !this.state.confirmpassVisible })}>
                          {this.state.confirmpassVisible ? (
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                          ):(
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
                          )}
                        </IconButton>
                      </span>
                      </div>
                      <span className='error-text p-0'>
                        <ErrorMessage name="confirmpass" component="div" className="error p-0" />
                      </span>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="profile-button">
            <button className="btn button-outline-profile-edit" type="button" data-test-id="button_outline_profile_edit_back" id="editBack"  onClick={() => this.props.navigation.goBack()}>Back</button>
            <button
            type='submit'
            disabled={this.state.submitPassword}
            // data-test-id="saveprofile-modal"
            className="btn button-green save-modal-block"
            >{this.state.submitPassword ? <CircularProgress size={30} className='text-white'/> : "Change Password"}</button> 
        </div>
      </form>
    }}
  </Formik>
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu id={this.props.id} navigation={this.props.navigation} title='Change Password'>
          {this.state.isloading ? 
          (<div className="home-category-block-content-norecord-loader"><CircularProgress className='text-default'/></div>)
           : 
           (
          <Paper style={{ width: "100%", overflow: "hidden" }} >
            <div className="content-wrapper">
              <div className="profile-conetnt">
                {this.edituserForm()}
              </div>
              {this.deleteModalVisible()}
            </div>
          </Paper>
          )} 
        </NavigationMenu>
      </ThemeProvider>
    )
  }
}