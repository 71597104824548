import React from "react";
// Customizable Area Start
import GuestNavigationMenuController, { Props } from "./GuestNavigationMenuController";
import { Box, Grid, styled } from "@material-ui/core";
import { MobileLogo, logo, arrowBackIos } from "./assets";
import {Link} from "react-router-dom";
// Customizable Area End

export default class GuestNavigationMenu extends GuestNavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }



  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainContainer>
        <Grid
          container
          justifyContent="space-between"
          className="rootNavigationGrid"
        >
          <Box className="w-100">
            <div>
              <div className="main-dashboard-wrapper">
                <div className={`${this.state.hide_menu_toggle === true ? "side-page-navigation open-hide-bar" : "side-page-navigation"}`}>
                  <div className={`${this.state.menu_toggle === true ? "mobile-menu open-menu" : "mobile-menu"}`}>
                    <span
                      data-test-id="show_menu_toggle"
                      onClick={() => this.onClickMenyToggle()}
                    >
                      <svg
                        className="nav-menu"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                      </svg>
                    </span>
                    <span
                      data-test-id="hide_menu_toggle"
                      onClick={() => this.onClickMenyToggleOff()}
                    >
                      <svg
                        className="close-nav"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    </span>
                    <div className="page-sidebar">
                      <div className="site-logo">
                        <Box onClick={() => this.onClickRedirectHomePage("Home")} data-test-id="on_click_redirect_home">
                          <div className="desktop-logo-back">
                            <img className="desktop-logo" src={logo} alt="logo" />
                          </div>
                          <img
                            className="mobile-logo"
                            src={MobileLogo}
                            alt="logo"
                          />
                        </Box>
                      </div>
                      <div className="navigation">
                        <nav className="main-nav">
                          <ul>
                            <li
                              className={`${this.props.title === "Browse Course" && "current"}`}>
                              <div onClick={() => this.onClickRedirectHomePage("GuestCourse")} data-test-id="on_click_redirect_Categoriessubcategories">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.0827 7.40788C16.4827 6.76621 16.7077 6.01621 16.6577 5.19121C16.5493 3.39954 15.1243 1.88288 13.3493 1.69121C11.0827 1.44121 9.16602 3.19954 9.16602 5.41621C9.16602 7.49121 10.841 9.16621 12.9077 9.16621C13.641 9.16621 14.3243 8.94954 14.8993 8.58288L16.9077 10.5912C17.2327 10.9162 17.766 10.9162 18.091 10.5912C18.416 10.2662 18.416 9.73288 18.091 9.40788L16.0827 7.40788ZM14.9993 15.8329V12.4995C14.9993 12.0412 15.3743 11.6662 15.8327 11.6662C16.291 11.6662 16.666 12.0412 16.666 12.4995V16.6662C16.666 17.5829 15.916 18.3329 14.9993 18.3329H3.33268C2.41602 18.3329 1.66602 17.5829 1.66602 16.6662V4.99954C1.66602 4.08288 2.41602 3.33288 3.33268 3.33288H6.68268C7.14102 3.33288 7.51602 3.70788 7.51602 4.16621C7.51602 4.62454 7.14102 4.99954 6.68268 4.99954H4.16602C3.70768 4.99954 3.33268 5.37454 3.33268 5.83288V15.8329C3.33268 16.2912 3.70768 16.6662 4.16602 16.6662H14.166C14.6243 16.6662 14.9993 16.2912 14.9993 15.8329ZM5.43268 14.9995H12.916C13.2577 14.9995 13.4577 14.6079 13.2493 14.3412L11.1243 11.5162C10.966 11.2912 10.6327 11.2912 10.466 11.5079L8.50768 14.0245L7.20768 12.4579C7.04102 12.2495 6.72435 12.2579 6.55768 12.4662L5.10768 14.3245C4.89102 14.5995 5.08268 14.9995 5.43268 14.9995ZM10.8327 5.41621C10.8327 6.56621 11.766 7.49954 12.916 7.49954C14.066 7.49954 14.9993 6.56621 14.9993 5.41621C14.9993 4.26621 14.066 3.33288 12.916 3.33288C11.766 3.33288 10.8327 4.26621 10.8327 5.41621Z"
                                  />
                                </svg>
                                <span className="hide-nav">Browse Course</span>
                              </div>
                            </li>
                            <li
                              data-test-id="id15"
                              className={`${this.props.title === "About raffles" && "current"
                                }`}>
                              <div id="id16" onClick={() => this.onClickRedirectHomePage("GuestAboutus")} data-test-id="on_click_redirect_AboutRaffles">
                                <svg
                                  height="20"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path className="id17" d="M9.16602 7.49984H10.8327V5.83317H9.16602M9.99935 16.6665C6.32435 16.6665 3.33268 13.6748 3.33268 9.99984C3.33268 6.32484 6.32435 3.33317 9.99935 3.33317C13.6743 3.33317 16.666 6.32484 16.666 9.99984C16.666 13.6748 13.6743 16.6665 9.99935 16.6665ZM9.99935 1.6665C8.905 1.6665 7.82137 1.88205 6.81032 2.30084C5.79927 2.71963 4.88061 3.33346 4.10679 4.10728C2.54399 5.67008 1.66602 7.7897 1.66602 9.99984C1.66602 12.21 2.54399 14.3296 4.10679 15.8924C4.88061 16.6662 5.79927 17.28 6.81032 17.6988C7.82137 18.1176 8.905 18.3332 9.99935 18.3332C12.2095 18.3332 14.3291 17.4552 15.8919 15.8924C17.4547 14.3296 18.3327 12.21 18.3327 9.99984C18.3327 8.90549 18.1171 7.82185 17.6983 6.81081C17.2796 5.79976 16.6657 4.8811 15.8919 4.10728C15.1181 3.33346 14.1994 2.71963 13.1884 2.30084C12.1773 1.88205 11.0937 1.6665 9.99935 1.6665ZM9.16602 14.1665H10.8327V9.1665H9.16602V14.1665Z" />
                                </svg>
                                <span data-test-id="id18" className="hide-nav">About Raffles</span>
                              </div>
                            </li>
                            <li
                              data-test-id="id25"
                              className={`${this.props.title === "Contact us" ? "current" : ""
                                }`}
                            >
                              <div onClick={() => this.onClickRedirectHomePage("GuestContactus")} data-test-id="on_click_redirect_Contactus">
                                <svg
                                  viewBox="0 0 20 20"
                                  width="20"
                                  height="20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M16.667 3.3335H3.33366C2.41699 3.3335 1.67533 4.0835 1.67533 5.00016L1.66699 15.0002C1.66699 15.9168 2.41699 16.6668 3.33366 16.6668H16.667C17.5837 16.6668 18.3337 15.9168 18.3337 15.0002V5.00016C18.3337 4.0835 17.5837 3.3335 16.667 3.3335ZM16.667 14.1668C16.667 14.6252 16.292 15.0002 15.8337 15.0002H4.16699C3.70866 15.0002 3.33366 14.6252 3.33366 14.1668V6.66683L9.11699 10.2835C9.65866 10.6252 10.342 10.6252 10.8837 10.2835L16.667 6.66683V14.1668ZM3.33366 5.00016L10.0003 9.16683L16.667 5.00016H3.33366Z"
                                  />
                                </svg>
                                <span className="hide-nav id26">Contact Us</span>
                              </div>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div
                        className="hide-bar"
                        data-test-id="handle_collape"
                        onClick={this.handleCollape}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 256 512"
                        >
                          <path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <Box className="right_sidebar navBox">
                <div className="page-wrapper">
                  <div className="header-wrapper">
                    <div className="page-title"> 
                    <Link to="/" >
                      <img className="back-btn" src={arrowBackIos}  />
                      </Link> 
                      <h6>
                        {this.props.title}
                      </h6>
                    </div>
                  </div>

                  <Box className="rootChildren">{this.props.children}</Box>
                </div>
              </Box>
              </div>
            </div>
          </Box>
        </Grid>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .rootNavigationGrid": {
    flexGrow: 1,
    width: "100%",
    "& .w-100": {
      width: "100%",
    }
  },
  "& .rootChildren": {
    padding: "0"
  },
  "& .navBox": {
    display: "flex",
    flexDirection: "column"
  },
  "& .page-title" : {
    display:"flex",
    alignItems:"start"
  },
  "& .back-btn": {
    marginRight: "10px",
    height:"20px",
    marginTop:"2px",
  }, 
})
// Customizable Area End
