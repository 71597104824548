import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getTokken: string;
  sucessModalVisible: boolean;
  fileUploadModalVisible: boolean;
  modalDeleteVisible: boolean;
  fileData: any;
  timeUpload: any;
  progressUpload: any;
  open: boolean;
  errorImg: boolean;
  profileEdit: boolean;
  dataUser: any
  isloading: boolean;
  imagesProfile: any;
  onSubmitFlag: boolean;
  imageProfileFlag: boolean;
  accountDeleteLoading: boolean;
  uploadTime: any;
  uploadProgressSecond: any;
  filesUploadFlag:null|string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditLecturerUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInput: any
  getProfileAllDetailsCallId:any;
  editProfileDetailsImagesCallId:any;
  deleteLectureProfileDetailsImagesCallId:any;
  editLecturerProfileDetailsCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      sucessModalVisible: false,
      modalDeleteVisible: false,
      fileUploadModalVisible: false,
      fileData: "",
      timeUpload: '',
      progressUpload: "0",
      open: false,
      getTokken: "",
      imagesProfile: {},
      isloading: false,
      errorImg: false,
      profileEdit: true,
      dataUser: {},
      onSubmitFlag: false,
      imageProfileFlag: false,
      accountDeleteLoading: false,
      uploadTime: '',
      uploadProgressSecond: "0",
      filesUploadFlag:null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.editProfileDetailsImagesCallId && responseJson){
        responseJson?.data && this.setState({fileUploadModalVisible: false,filesUploadFlag:"",imageProfileFlag:false, imagesProfile: {}, fileData: null}, () => {
          this.getAllProfileDetails()
        })
        responseJson?.data && toast.success('Profile updated successfully.')
        this.callFunctionLecture()
      }
      this.getProfileDetailsresponse(apiRequestCallId, responseJson)
      this.shortCodeTwo(apiRequestCallId, responseJson)
      this.shortCodeThree(apiRequestCallId, responseJson)
  }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    let allToken = await getStorageData("token") || ''
    this.setState({ getTokken: allToken }, () => {
      this.getAllProfileDetails()
    })
    // Customizable Area End
  }

  // Customizable Area Start

  getProfileDetailsresponse = (apiRequestCallId:any, responseJson:any) => {
    if(apiRequestCallId === this.getProfileAllDetailsCallId && responseJson){
      this.setState({dataUser:responseJson.data,isloading:false}) 
      setStorageData("profile_image", responseJson?.data?.attributes?.profile_picture?.url)
    }
  }

  shortCodeTwo = (apiRequestCallId:any, responseJson:any) => {
    if(apiRequestCallId === this.deleteLectureProfileDetailsImagesCallId && responseJson){
      responseJson?.profile_picture && this.getAllProfileDetails()
      responseJson?.profile_picture && toast.success('Image deleted Successfully.')
      removeStorageData('profile_image')
      this.callFunctionLecture()
    }
  };

  shortCodeThree = (apiRequestCallId:any, responseJson:any) => {
    if(apiRequestCallId === this.editLecturerProfileDetailsCallId && responseJson){
      responseJson?.data && this.editProfileResponce()
    }
  }

  editProfileResponce = () => {
      this.setState({sucessModalVisible:true,profileEdit:true,onSubmitFlag:false})
      this.getAllProfileDetails()
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    this.setState({ fileData: droppedFile });
  };

  callFunctionLecture = () => {
    this.setState({ uploadTime: Date.now() });
    this.startSecondUploadLecture()
  }

  startSecondUploadLecture = () => {
    const intervalIdSecond = setInterval(() => {
      // Update the upload progress based on the elapsed time
      const elapsedTimeSecond = Date.now() - this.state.uploadTime;
      const uploadProgressSecond = Math.min(Math.floor((elapsedTimeSecond / 5000) * 100), 100);

      this.setState({ uploadProgressSecond: uploadProgressSecond });

      // Check if upload progress reaches 100%
      uploadProgressSecond >= 100 && clearInterval(intervalIdSecond)
      uploadProgressSecond >= 100 && this.uploadComplete()
    }, 100);
  };


  handleFileChangeProfile = (event: any) => {

    const selectedFile = event.target.files[0];
    const timeUpload = Date.now();
    this.setState({ imagesProfile: selectedFile, filesUploadFlag: event.target.value});


    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      // File size exceeds 5MB
      this.setState({ open: true })
      event.target.value = null; // Reset the file input
      return;
    }
    this.setState({ open: false })

    // Set the file and upload time in state
    this.setState({ fileData: selectedFile, timeUpload: timeUpload });

    // Start the upload process
    this.startUpload();
  };

  startUpload = () => {
    const intervalId = setInterval(() => {
      // Update the upload progress based on the elapsed time
      const elapsedTime = Date.now() - this.state.timeUpload;
      const progressUpload = Math.min(Math.floor((elapsedTime / 5000) * 100), 100);

      this.setState({ progressUpload: progressUpload });

      // Check if upload progress reaches 100%
      if (progressUpload >= 100) {
        clearInterval(intervalId); // Stop the upload progress
        this.uploadComplete(); // Call the upload complete function
      }
    }, 10);
  };

  uploadComplete = () => {
    this.setState({ fileData: this.state.fileData });
  };

  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  
  handleClick = () => {
    document.getElementById('profileImg')?.click(); // Programmatically trigger the file input click
  };

  onClickProfileEdit = () => {
    this.setState({ profileEdit: false })
    this.props.navigation.navigate("ChangePassword");
  }

  onClickProfileEditBack = () => {
    this.setState({ profileEdit: true })
    this.getAllProfileDetails()
  }

  onClickChangeDisabled = () => {
    this.setState({ profileEdit: false })
  }

  accountDelete = () => {
    this.setState({ accountDeleteLoading: true })
  }

  getAllProfileDetails = async() => {
    const UserId = await getStorageData('user_id')
    this.setState({isloading:true})
    if (!this.state.getTokken) {
      return;
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.getTokken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileAllDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllProfileEndPoint + UserId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editProfileDetailsImages = () => {
    this.setState({imageProfileFlag:true})
    if (!this.state.getTokken) {
      return;
    }

    const formData = new FormData();
    formData.append("account[profile_picture]", this.state.imagesProfile)

    const header = {
      token: this.state.getTokken,
      redirect: 'follow'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileDetailsImagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update?id=${this.state.dataUser?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteLecturePicture = () => {
    if (!this.state.getTokken) {
      return;
    }

    const formData = new FormData();
    formData.append("account[profile_picture]", "")

    const header = {
      token: this.state.getTokken,
      redirect: 'follow'
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteLectureProfileDetailsImagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editLecturerProfileDetails = (editdata:any) => {
    if (!this.state.getTokken) {
      return;
    }

    const formData = new FormData();
    if(editdata?.card?.type){
      formData.append("account[identification_card]", editdata?.card)
    }
    if(editdata?.resume?.type){
      formData.append("account[resume]", editdata?.resume)
    }
    formData.append("account[first_name]", editdata.fname)
    formData.append("account[last_name]", editdata.lname)
    formData.append("account[email]", editdata.email)
    formData.append("account[full_phone_number]", editdata.phno)
    formData.append("account[date_of_birth]", editdata.date)
    formData.append("account[qualification]", editdata.qualification)
    formData.append("account[biography]", editdata.biography)
    formData.append("account[experience]", editdata.experience)
    formData.append("account[major]", editdata.major)
    formData.append("account[department]", editdata.department)
    
    const header = {
      token: this.state.getTokken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editLecturerProfileDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EditProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
