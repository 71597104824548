import React from 'react';
import { logo } from './assets';
import ForgotPasswordController, { Props } from './ForgotPasswordController';
import { Link } from 'react-router-dom';

export default class PasswordChange extends ForgotPasswordController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <>
                <div className="main-wrapper">
                    <div className="signin-form-wrapper">
                        <div className="col-6">
                            <div className="form-image">
                                <div className="login-image">
                                    <img src={logo} alt="logo" width={300} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 all-set-block">
                            <div className="close-btn">
                                <Link to="/ForgotPassword">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                    </svg>
                                </Link>
                            </div>
                            <div className="login-form-wrapper">
                                <div className="set-image">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M33 5.5C17.82 5.5 5.5 17.82 5.5 33C5.5 48.18 17.82 60.5 33 60.5C48.18 60.5 60.5 48.18 60.5 33C60.5 17.82 48.18 5.5 33 5.5ZM33 55C20.8725 55 11 45.1275 11 33C11 20.8725 20.8725 11 33 11C45.1275 11 55 20.8725 55 33C55 45.1275 45.1275 55 33 55ZM27.5 38.9675L43.67 22.7975C44.7425 21.725 46.5025 21.725 47.575 22.7975C48.6475 23.87 48.6475 25.6025 47.575 26.675L29.4525 44.7975C28.38 45.87 26.6475 45.87 25.575 44.7975L18.4525 37.675C17.38 36.6025 17.38 34.87 18.4525 33.7975C18.9663 33.2826 19.6638 32.9932 20.3912 32.9932C21.1187 32.9932 21.8162 33.2826 22.33 33.7975L27.5 38.9675Z" fill="#37657F" />
                                    </svg>
                                </div>
                                <form>
                                    <div className="block-heading">
                                        <h4>All Set Now!</h4>
                                        <p>Your password has been reset now.</p>
                                    </div>
                                    <div className="block-content">
                                        <div className="form-group">
                                            <Link to='/Signin' style={{ textDecoration: 'none' }}>
                                                <input type="button" className="btn button" defaultValue="Login" />
                                            </Link>
                                            <span className="or">Or</span>
                                            <Link to='/Signup' style={{ textDecoration: 'none' }}>
                                                <input type="button" className="btn button-outline" defaultValue="Sign Up" />
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
