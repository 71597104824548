Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.createLiveSessionsAPiEndPoint = "bx_block_content_management/live_sessions";
exports.editLiveSessionsApiEndPoint = "bx_block_content_management/live_sessions/"
exports.deleteLiveSessionsApiEndPoint = "bx_block_content_management/live_sessions/"
exports.listAllCoursesAPIEndPoint = "bx_block_profile/courses/lecturer_course"
exports.listAllSessionAPIEndPoint = "bx_block_content_management/live_sessions/my_live_sessions"
exports.listSingleSessionAPIEndPoint = "bx_block_content_management/live_sessions/"
exports.examplePutAPiMethod = "PUT";
exports.exampleDeleteAPiMethod = "DELETE";
// Customizable Area End