import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";
import moment from "moment";

export interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string | null;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: number;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  full_name: string | null;
  gender: string;
  date_of_birth: string;
  age: number | null;
  middle_name: string;
  qualification: string;
  country: string;
  city: string;
  occupation: string;
  reset_password_token: string | null;
  reset_password_sent_at: string | null;
  department: string | null;
  major: string | null;
  experience: string | null;
  biography: string | null;
  automatic_reminder: boolean;
}

interface Course {
  id: number;
  course_name: string;
  created_at: string;
  updated_at: string;
  title: string;
  information: string;
  description: string;
  profile_id: number;
  category_id: number;
  language: string;
  level: string;
  price: number;
  course_type: string;
  no_of_lessons: number;
  total_duration_of_lessons: number;
}

interface Question {
  data: any[]; // Assuming this will be an array of question objects
}

interface Assessment {
  id: number;
  account_id: number;
  course_id: number;
  started: boolean;
  complited: boolean;
  created_at: string;
  updated_at: string;
  track: any | null; // Assuming type of track
  name: string;
  passing_score: number | null;
  retake: number | null;
  status: string;
  creaeted_by: string; // Typo: Corrected from "creaeted_by" to "created_by"
  course_image: string;
  score: number | null;
  attempt_question: number;
  total_question: number;
}

interface StudentRecord {
  // Define properties if needed
}

interface Data {
  id: string;
  type: string;
  attributes: {
      account: Account;
      course: Course;
      name: string;
      passing_score: number | null;
      retake: number | null;
      questions: Question;
      Assessments: Assessment;
      StudentRecord: StudentRecord[];
  }
}

interface Response {
  data: Data[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  AllAssessmentsData:any;
  ongoingAssessmentsData:any;
  CompletedAssessmentsData:any;
  complitedAssessmentsGrid:any;
  resultModal:boolean;
  apiToken:string;
  getLoading:boolean;
  getAnswer:boolean;
  courseDetailsData:any;
  allQuestionData:any;
  errorFlag:boolean;
  selectAssessement:string;
  selectResult:any;
  studentRecord:any;
  searchHistoryData:string[];
  searchValue:string;
  searchHistory:boolean;
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  retakeFlag:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmenttestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllQuestionApiCallId: string = "";
  submitAnswerApiCallId: string = "";
  studentAssessmentsApiCallId: string = "";
  getAllAssessmentsApiCallId: string = "";
  getApiViewResultAssessementApiCallId:string = "";
  retakeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      AllAssessmentsData:[],
      ongoingAssessmentsData:[],
      CompletedAssessmentsData:[],
      complitedAssessmentsGrid:[],
      resultModal:false,
      apiToken:"",
      getLoading:false,
      getAnswer:false,
      courseDetailsData:{},
      allQuestionData:[],
      errorFlag:false,
      selectAssessement:"Ongoing Assessments",
      selectResult:{},
      studentRecord:{},
      searchHistoryData:[],
      searchValue:"",
      searchHistory:false,
      divRef: React.createRef(),
      retakeFlag:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getAllQuestionApiCallId && responseJson){
        let finalAns = this.onCallResponsemMultipleSelect(responseJson)
        this.setState({courseDetailsData:responseJson.data,allQuestionData:finalAns,getLoading:false,studentRecord:responseJson.data.attributes.StudentRecord})
      }

      this.responseData(apiRequestCallId, responseJson)
      this.responseDataSecond(apiRequestCallId, responseJson)
      this.responseDataThrid(apiRequestCallId, responseJson)
      this.responseRetake(apiRequestCallId, responseJson)
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events

  onCallResponsemMultipleSelect = (responseJson: any) => {
    return responseJson.data.attributes.questions.data.map((objs: any) => {
      switch (objs.attributes.question_type) {
        case "multiple_select":
          return {
            ...objs,
            attributes: {
              ...objs.attributes,
              answers: objs.attributes.answers.map((subs: any, index: number) => {
                return {
                  ...subs,
                  checked: responseJson.data.attributes.StudentRecord.find((findobj: any) => findobj.question_id === objs.attributes.id)?.answer_ids.find((subobj: any) => parseInt(subobj) === subs.id) ? true : false,
                };
              }),
            },
            final_answer: responseJson.data.attributes.StudentRecord.find((findobj: any) => findobj.question_id === objs.attributes.id)?.answer_ids || [],
          };
        case "subjective":
          return {
            ...objs,
            final_answer: responseJson.data.attributes.StudentRecord.find((findobj: any) => findobj.question_id === objs.attributes.id)?.subjective_answer || "",
          };
        default:
          return {
            ...objs,
            final_answer: responseJson.data.attributes.StudentRecord.find((findobj: any) => findobj.question_id === objs.attributes.id)?.answer_id.toString() || "",
          };
      }
    });
  };
  

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  responseData = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.submitAnswerApiCallId && responseJson) && responseJson?.data && this.finalSubmit()
  }

  responseDataSecond = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.getAllAssessmentsApiCallId && responseJson) && responseJson?.data && this.onCallFunctionSecond(responseJson)
  }

  responseRetake = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.retakeApiCallId && responseJson) && this.handleRedirectRetakeTest(responseJson)
  }

  responseDataThrid = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.getApiViewResultAssessementApiCallId && responseJson) && responseJson?.data && this.onCallFunctionThrid(responseJson)
  }

  onCallFunctionSecond = (responseJson:Response) => {
    let ongoingAssessments =  responseJson.data.filter((item:Data) => item.attributes.Assessments.status === "OngoingAssessments")
    let complitedAssessments =  responseJson.data.filter((item:Data) => item.attributes.Assessments.status === "ComplitedAssessments" || item.attributes.Assessments.status === "SubmitedAssessments")
    let complitedAssessmentsGrid =  responseJson.data.filter((item:Data) => item.attributes.Assessments.status === "ComplitedAssessments" || item.attributes.Assessments.status === "SubmitedAssessments").reverse()
    this.setState({AllAssessmentsData:responseJson.data, getLoading:false, ongoingAssessmentsData:ongoingAssessments, CompletedAssessmentsData:complitedAssessments,complitedAssessmentsGrid:complitedAssessmentsGrid})
  }

  handleRedirectRetakeTest = (responseJson:any) => {
    responseJson?.data && this.setState({retakeFlag:false})
    !responseJson?.data && responseJson.message && this.setState({retakeFlag:true})
  }

  onCallFunctionThrid = (responseJson:any) => {
    this.setState({selectResult:responseJson?.data})
  }

  onClickOpenModal = (idss:string) => {
    this.getApiViewResultAssessement(idss)
    this.retakeApiCall(idss)
    this.setState({resultModal:true})
  }

  onClickCloseModal = () => {
    this.setState({resultModal:false, retakeFlag:false})
  }

  onClickRedirectTest = (idss:string) => {
    this.props.navigation.navigate("Assessmenttest",{id:idss})
  }

  handleRatakeFlag = () => {
    const pathname = window.location.pathname
    const isWordPresent = pathname.includes("retake");
    return isWordPresent
  }

  onClickRedirectRetakeTest = (idss:string) => {
    this.props.navigation.navigate("AssessmentRetakeTest",{id:idss})
  }

  retakeApiCall = (idss:string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.retakeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.retakeTestApiEndPoint + idss
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  finalSubmit = () => {
    this.setState({getAnswer:false})
    this.props.navigation.navigate("Assessment")
  }

  onClickGoBack = () => {
    this.props.navigation.goBack()
  }

  async componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);
    let sToken = await getStorageData("token")
    this.setState({ apiToken: sToken }, () => {
      this.callGetParam()
      this.getApiAssessement()
    });
    this.handleRatakeFlag()
  }

  callGetParam = () => {
    const getParam = this.props.navigation.getParam("id");
    getParam && this.getApiQuestion(getParam)
  }

  getApiQuestion = (Param:string) => {
    this.setState({getLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllQuestionsAPIEndPoint + Param
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  onChangeRadio = (event:string,index:number,data:any) => {
    let finalAns = this.state.allQuestionData.map((objs:any,inds:number) => {
      if(index === inds){
        return({
        ...objs,
        final_answer: event
      })
      }else{
        return objs
      }
    })
    this.setState({allQuestionData:finalAns})
    this.changeStatusRadioAndMulti(data, event, index+1)
  }

  onChangeSubjective = (event:any,index:number) => {
    let finalAnsSubjective = this.state.allQuestionData.map((objs:any,inds:number) => {
      if(index === inds){
        return({
        ...objs,
        final_answer: event.target.value
      })
      }else{
        return objs
      }
    })
    this.setState({allQuestionData:finalAnsSubjective})
  }

  onSubmitData = () => {
  let transformed = {
    assessment_id: this.state.courseDetailsData.id,
    question_answer: {},
    retake: this.handleRatakeFlag()
  };

  const validate = this.state.allQuestionData.filter((item:any) => !item.final_answer?.length );
    if(validate.length){
      this.setState({errorFlag:true})
    }else{
      this.setState({errorFlag:false})
      this.state.allQuestionData.forEach((item:any) => {
        const questionId = item.attributes.id;
        const questionType = item.attributes.question_type;
        
        item.final_answer && this.allloopcall(item, questionType, transformed, questionId)
      });
      this.submitAnswer(transformed) 
    } 
  }

  allloopcall = (item:any, questionType:string, transformed:any,questionId:any) => {
    (questionType === 'single_select' || questionType === 'dropdown') && this.questionTypeRadioAll(item, transformed, questionId)
    questionType === 'subjective' && this.questionTypesubjective(item, transformed, questionId)
    questionType === 'multiple_select' && this.questionTypemultipleSelect(item, transformed, questionId)
  }

  questionTypeRadioAll = (item:any, transformed:any, questionId:any) => {
    const answers = item.final_answer
    let newValue = transformed.question_answer[questionId] = [parseInt(answers)]
    return newValue;
  }

  questionTypesubjective = (item:any, transformed:any, questionId:any) => {
    const answers = item.final_answer
    let newValuess = transformed.question_answer[questionId] = [answers]
    return newValuess
  }

  questionTypemultipleSelect = (item:any, transformed:any, questionId:any) => {
    const answer = item.attributes.answers.filter((item:any) => item.checked === true);
    const questionIds = answer.map((item:any) => item.id);
    let newValuesss = transformed.question_answer[questionId] = questionIds
    return newValuesss
  }

  handleChangeMultichoice = (event:any, subindex:number, mainIndex:number) => {
    let finalAns = this.state.allQuestionData.map((objs:any,inds:number) => {
      if(mainIndex === inds){
        return({
        ...objs,
        attributes:{
          ...objs.attributes,
          answers: objs.attributes.answers.map((subs:any,index:number) => {
            if(subindex === index){
              return ({
                ...subs,
                checked:event.target.checked
              })
            }else{
              return subs
            }
          }),
        }
      })
      }else{
        return objs
      }
    })

    let finalAnswer = finalAns.map((objs:any,inds:number) => {
      if(mainIndex === inds){
        return({
        ...objs,
        final_answer:objs.attributes.answers.filter((item:any) => item.checked === true).map((item:any) => item.id)
      })
      }else{
        return objs
      }
    })
    this.setState({allQuestionData:finalAnswer})
    this.changeStatusRadioAndMulti(finalAns[mainIndex], event.target.checked, mainIndex+1)
  }


  submitAnswer = (Param:any) => {
    this.setState({getAnswer:true})
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken
    };
    const httpBody = {
      "data":Param
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitAnswerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assessmentTestEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  changeStatusRadioAndMulti = (Param:any, newValue:any , dataLength:number) => {
    let httpBody 
    Param.attributes.question_type === "single_select" && (httpBody = this.theReturnDataRadioDropdown(Param, newValue, dataLength))
    Param.attributes.question_type === "dropdown" && (httpBody = this.theReturnDataRadioDropdown(Param, newValue, dataLength))
    Param.attributes.question_type === "subjective" && (httpBody = this.theReturnDataSubjective(Param, newValue, dataLength))
    Param.attributes.question_type === "multiple_select" && (httpBody = this.theReturnDatamMultipleSelect(Param, dataLength))
  
    const header = {
      "Content-Type": "application/json",
      token: this.state.apiToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.studentAssessmentsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentAssessmentsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  theReturnDataRadioDropdown = (Param:any, newValue:any, dataLength:number) => {
    let httpBody = {
        "student_assessment":{
          "assessment_id":parseFloat(this.state.courseDetailsData.id),
          "question_id":parseFloat(Param.id),
          "answer_id":newValue,
          "status": dataLength === this.state.allQuestionData.length ? "complited" : "started"
        }
      }
    return httpBody
  }

  theReturnDataSubjective = (Param:any, newValue:any, dataLength:number) => {
    let httpBody = {
      "student_assessment":{
        "assessment_id":parseFloat(this.state.courseDetailsData.id),
        "question_id":parseFloat(Param.id),
        "subjective_answer" : newValue,
        "status": dataLength === this.state.allQuestionData.length ? "complited" : "started"
      }
    }
    return httpBody
  }

  theReturnDatamMultipleSelect = (Param:any, dataLength:number) => {
    const answer = Param.attributes.answers.filter((item:any) => item.checked === true);
    const questionIds = answer.map((item:any) => item.id);
    let httpBody = {
      "student_assessment":{
        "assessment_id":parseFloat(this.state.courseDetailsData.id),
        "question_id":parseFloat(Param.id),
        "answer_ids" : questionIds,
        "status": dataLength === this.state.allQuestionData.length ? "complited" : "started"
      }
    }
    return httpBody
  }

  getApiAssessement = () => {
    this.setState({getLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAssessmentsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllAssessmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeSelector = (event:any) => {
    this.setState({selectAssessement:event.target.value})
  }

  getApiViewResultAssessement = (param:any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.apiToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiViewResultAssessementApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AssessmentViewResultAPIEndPoint + param
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  selectHistoryDelete = (index:number) => {
    let deleteData = this.state.searchHistoryData.filter((object:any, indx:number) => indx !== index)
    this.setState({searchHistoryData:deleteData})
    setStorageData("searchHistory",JSON.stringify(deleteData))
  }

  onClickSearchModalOpen = () => {
    this.setState({searchHistory:true})
    this.onSearchLogic()
  }

  onSearchLogic = () => {
    (this.state.searchValue.length >= 3 && !this.state.searchHistoryData.includes(this.state.searchValue)) && this.onSearchLogicFunction()
  }

  onSearchLogicFunction = () => {
    let searchData = [ this.state.searchValue,...this.state.searchHistoryData]
    this.setState({searchHistoryData: searchData})
    setStorageData("searchHistory", JSON.stringify(searchData))
    return true;
  }

  onsearchValue = (event:any) => {
    this.setState({searchValue:event.target.value})
    window.location.pathname !== "/Searching" && this.onsearchValueFunction(event.target.value)
  }

  onClickSearchValue = (value:string) => {
    this.setState({searchValue:value,searchHistory:false})
    window.location.pathname !== "/Searching" && this.onsearchValueFunction(value)
  }

  onsearchValueFunction = (value:any) => {
    setStorageData("searchValue",value)
    this.props.navigation.navigate('Searching')
  }

  onClickSearchModalClose = () => {
    this.setState({searchHistory:false})
    this.onSearchLogic()
  }

  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = (event:any) => {
    (this.state.divRef.current && !this.state.divRef.current.contains(event.target)) && this.onClickSearchModalClose()
  };

  clearAllHistory = () => {
    removeStorageData('searchHistory')
    this.setState({searchHistoryData:[],searchValue:""})
    this.onCallHistory()
  }

  onCallHistory = async() => {
    let SearchHistory = await getStorageData("searchHistory")
    SearchHistory && this.setState({searchHistoryData:JSON.parse(SearchHistory)})
  }

  dateFormateFunction = () => {
    const dates = moment().format('DD MMM YYYY')
    return dates
  }
  // Customizable Area End
}
