import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  pieChartData: any;
  pieChartNumberOfUsers: any;
  userSessionData: any;
  isCalendarOpenUserSession: boolean;
  isCalendarOpenFirstChart: boolean;
  isCalendarOpenThirdChart: boolean;
  isCalendarOpenParticipantAttendance: boolean;
  firstChartData: any;
  setDateRangeForFirstChart: string | null;
  setDateRangeForUserSession: string | null;
  attendanceData: any;
  setDateRangeForThirdChart: string | null;
  setDateRangeForPartcipantAttendancet: string | null;
  searchTerm: string;
  participantAttendanceData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  apiGetUserSessionData: any;
  apiGetFirstChartData: any;
  apiGetParticipantAttendance: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      pieChartData: [
        ["Status", "Users"],
        ["Attended", 0],
        ["Not Attended", 0],
      ],
      pieChartNumberOfUsers: { attended: 0, notAttended: 0 },
      userSessionData: [],
      isCalendarOpenUserSession: false,
      isCalendarOpenFirstChart: false,
      isCalendarOpenThirdChart: false,
      isCalendarOpenParticipantAttendance: false,
      firstChartData: [],
      setDateRangeForFirstChart: null,
      setDateRangeForUserSession: null,
      attendanceData: [],
      setDateRangeForThirdChart: null,
      setDateRangeForPartcipantAttendancet: null,
      searchTerm: "",
      participantAttendanceData: [
        {
          name: "Theresya Monna",
          time: "10:00PM to 11:00PM",
          date: "10 May 2023",
          attended: "YES",
        },
        {
          name: "Jenifer Ardy",
          time: "08:00PM to 10:00PM",
          date: "09 May 2023",
          attended: "NO",
        },
        {
          name: "Jong Dae",
          time: "06:00PM to 07:00PM",
          date: "08 May 2023",
          attended: "YES",
        },
        {
          name: "Theresya Monna",
          time: "05:00PM to 07:00PM",
          date: "07 May 2023",
          attended: "NO",
        },
        {
          name: "Theresya Monna",
          time: "08:00PM to 09:00PM",
          date: "06 May 2023",
          attended: "YES",
        },
        {
          name: "Theresya Monna",
          time: "08:00AM to 10:00AM",
          date: "05 May 2023",
          attended: "YES",
        },
      ],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleSearchChange = this.handleSearchChange.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      apiRequestCallId === this.apiGetDataCallId &&
        responseJson &&
        this.reciveDataForPieChart(responseJson);
      apiRequestCallId === this.apiGetUserSessionData &&
        responseJson &&
        this.reciveDataForUserSession(responseJson);
      apiRequestCallId === this.apiGetFirstChartData &&
        responseJson &&
        this.reciveDataForFirstChart(responseJson);
      apiRequestCallId === this.apiGetParticipantAttendance &&
        responseJson &&
        this.reciveDataForParticipantAttendance(responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start

    // Customizable Area End
  }

  getToken = () => {
    this.setState({ token: localStorage.getItem("token") }, () => {
      this.getPieChartData(null);
      this.getUserSessionsData(null);
      this.getFirstChartData(null);
      this.getPartcipantAttendancetData(null);
    });
  };

  // Customizable Area Start

  toggleDatePickerUserSession = () => {
    this.setState({
      isCalendarOpenUserSession: !this.state.isCalendarOpenUserSession,
    });
  };
  toggleDatePickerFirstChart = () => {
    this.setState({
      isCalendarOpenFirstChart: !this.state.isCalendarOpenFirstChart,
    });
  };

  toggleDatePickerThirdChart = () => {
    this.setState({
      isCalendarOpenThirdChart: !this.state.isCalendarOpenThirdChart,
    });
  };

  toggleDatePickerParticipantAttendance = () => {
    this.setState({
      isCalendarOpenParticipantAttendance: !this.state
        .isCalendarOpenParticipantAttendance,
    });
  };

  handleCloseFirstChart = () => {
    this.setState({ isCalendarOpenFirstChart: false });
  };

  handleCloseUserSession = () => {
    this.setState({ isCalendarOpenUserSession: false });
  };

  handleCloseThirdChart = () => {
    this.setState({ isCalendarOpenThirdChart: false });
  };

  handleCloseParticipantAttendance = () => {
    this.setState({ isCalendarOpenParticipantAttendance: false });
  };

  handleSearchChange(event: any) {
    this.setState({ searchTerm: event.target.value });
  }

  navigateToHomePage = () => {
    this.props.navigation.navigate("MyCoursesLecturer");
  };

  reciveDataForPieChart = (responseJson: any) => {
    if (responseJson.message) {
      return;
    } else {
      this.setState({
        pieChartData: [
          ["Status", "Users"],
          ["Attended", Math.abs(responseJson.total_participants_participated)],
          [
            "Not Attended",
            Math.abs(responseJson.total_participants_not_attended),
          ],
        ],
      });

      this.setState({
        pieChartNumberOfUsers: {
          attended: Math.abs(responseJson.total_participants_participated),
          notAttended: Math.abs(responseJson.total_participants_not_attended),
        },
      });
    }
  };

  reciveDataForUserSession = (responseJson: any) => {
    this.setState({
      userSessionData: responseJson,
    });
  };

  reciveDataForFirstChart = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        firstChartData: null,
      });
    } else {
      this.setState({
        firstChartData: [
          ["Date", "Regular Users", "New Users"],
          ...responseJson.session_data.map(
            (session: {
              session_date: string;
              old_users: any;
              new_users: any;
            }) => {
              return [
                this.formatDateForParticipantAttendance(session.session_date, true),
                session.old_users,
                session.new_users,
              ];
            }
          ),
        ],
      });
    }
  };

  reciveDataForParticipantAttendance = (responseJson: any) => {
    this.setState({ attendanceData: responseJson });
  };

  getPartcipantAttendancetData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.startDate}&end_date=${selectDate.endDate}`;
      this.setState({
        setDateRangeForPartcipantAttendancet:
          selectDate.startDate + "/" + selectDate.endDate,
      });
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParticipantAttendance = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.participantAttendanceApiEndPoint +
        `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPieChartData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.startDate}&end_date=${selectDate.endDate}`;
      this.setState({
        setDateRangeForThirdChart:
          selectDate.startDate + "/" + selectDate.endDate,
      });
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sessionReportingZoomApiEndPoint +
        `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserSessionsData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.startDate}&end_date=${selectDate.endDate}`;
      this.setState({
        setDateRangeForUserSession:
          selectDate.startDate + "/" + selectDate.endDate,
      });
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserSessionData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userSessionsZoomApiEndPoint + `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFirstChartData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.startDate}&end_date=${selectDate.endDate}`;
      this.setState({
        setDateRangeForFirstChart:
          selectDate.startDate + "/" + selectDate.endDate,
      });
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFirstChartData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.firstChartZoomApiEndPoint + `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onSubmitThirdChart = async (values: any) => {
    this.getPieChartData(this.formatDate(values));
  };

  onSubmitPartcipantAttendance = async (values: any) => {
    this.getPartcipantAttendancetData(this.formatDate(values));
  };

  onSubmitUserSessionDate = async (values: any) => {
    this.getUserSessionsData(this.formatDate(values));
  };

  onSubmitFirstChartDate = async (values: any) => {
    this.getFirstChartData(this.formatDate(values));
  };

  formatDate = (value: any) => {
    const startDateValue = new Date(value.startDate);
    const endDateValue = new Date(value.endDate);

    const startDateFormat = `${startDateValue.getFullYear()}-${String(
      startDateValue.getMonth() + 1
    ).padStart(2, "0")}-${String(startDateValue.getDate()).padStart(2, "0")}`;
    const endDateFormat = `${endDateValue.getFullYear()}-${String(
      endDateValue.getMonth() + 1
    ).padStart(2, "0")}-${String(endDateValue.getDate()).padStart(2, "0")}`;

    return { startDate: startDateFormat, endDate: endDateFormat };
  };

  formatDateForParticipantAttendance = (dateString: string, firstChart: boolean) => {
    const currentDate = new Date(dateString);
    return currentDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: firstChart ? undefined : "numeric",
    });
  }
  
  // Customizable Area End
}
