import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
interface Course {
  id: string;
  type: string;
  attributes: {
      course_name: string;
      title: string;
      information: string;
      description: string;
      profile_id: number;
      category_id: number;
      language: string;
      level: string;
      price: number;
      course_type: string;
      no_of_lessons: number;
      total_duration_of_lessons: number;
      lessons: {
          data: Lesson[];
      };
      course_image: {
          url: string;
      };
      purchased: boolean | null; // adjust type accordingly
      average_rating: number;
      lecturer: string;
  };
}

interface Lesson {
  id: string;
  type: string;
  attributes: {
      title: string | null;
      description: string;
      duration: string | null;
      lesson_video: {
          url: string;
      };
      lesson_attachment: {
          url: string;
      } | null;
      video_subtitle: string | null;
      lesson_image: {
          url: string;
      };
  };
}

interface CourseData {
  data: Course[];
}

interface AnswerType {
  name: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  allCourse:Course[];
  questionsList:AnswerType[];
  allQuestion:any;
  errors:{[key: string]: string};
  submitFlag:boolean;
  onDeleteOpen:boolean;
  removeAssessmentIds:string[];
  dropdownData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmenttestCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiAllCourseApiCallId:string = "";
  createApiAssessmentApiCallId:string = "";
  editApiAssessmentApiCallId:string = "";
  singleCreateApiAssessmentApiCallId:string = "";
  deleteApiAssessmentApiCallId:string = "";
  deleteQuestionApiAssessmentApiCallId:string = "";
  getApiSingleAssessmentApiCallId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      allCourse:[],
      dropdownData: [
        { answer: "" },
        { answer: "" },
      ],
      questionsList:[
        {
          name:"Multiple Choice Answer",
          value:"multiple_select"
        },
        {
          name:"Single Choice Answer",
          value:"single_select"
        },
        {
          name:"DropDown Answer",
          value:"dropdown"
        },
        {
          name:"Subjective Answer",
          value:"subjective"
        }
      ],
      allQuestion: {
        name: "",
        course_id: "",
        passing_score:"",
        retake: "",
        questions_attributes: [
          {
            marks: '',
            question_type: "multiple_select",
            question: "",
            answers_attributes: [
              { answer: "" },
              { answer: "" },
              { answer: "" },
              { answer: "" }
            ],
            correct_answer: ""
          }
        ]
      },
      errors:{},
      submitFlag:false,
      onDeleteOpen:false,
      removeAssessmentIds:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const Idss = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.responseDataAllCourse(Idss, response)
      this.responseCreateAssessment(Idss, response)
      this.responseEditAssessment(Idss, response)
      this.responseSingleAssessment(Idss, response)
      this.responseDeleteAssessment(Idss, response)
    }
    
    // Customizable Area End
  }
  responseDataAllCourse = (apiRequestCallId:string, responseJson:CourseData) => {
    (apiRequestCallId === this.getApiAllCourseApiCallId && responseJson) && this.getAllCoursesSessions(responseJson)
  }

  responseCreateAssessment = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.createApiAssessmentApiCallId && responseJson) && this.CreateAssessmentSetError(responseJson)
  }

  responseEditAssessment = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.editApiAssessmentApiCallId && responseJson) && this.EditAssessmentSetError(responseJson)
  }

  responseSingleAssessment = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.getApiSingleAssessmentApiCallId && responseJson) && this.singlrAssesmentResonceSet(responseJson)
  }

  responseDeleteAssessment = (apiRequestCallId:string, responseJson:any) => {
    (apiRequestCallId === this.deleteApiAssessmentApiCallId && responseJson) && this.deleteAssesmentResonceSet(responseJson)
  }

  deleteAssesmentResonceSet = (responseJson:any) => {
    if(responseJson.message === "Deleted successfully"){
      toast.success("Assessment Deleted successfully.")
      this.onClickCloseDelete()
      this.onClickGoBack()
    }
    this.setState({submitFlag:false})
  }

  getAllCoursesSessions = (responseJson: any) => {
    if(responseJson.data) {
      this.setState({allCourse:responseJson.data})
    } else {
      this.props.navigation.navigate("AssessmenttestList")
      toast.error("No course record available for the lecturer.")
    }
  }
  
  singlrAssesmentResonceSet = (responseJson:any) => {
    if(responseJson.data){
      const {name, Assessments, passing_score, retake, questions } = responseJson.data.attributes

      this.setState((prevState) => ({
        allQuestion: {
          ...prevState.allQuestion,
          name: name,
          course_id:Assessments.course_id,
          passing_score:passing_score,
          retake:retake,
          questions_attributes: questions.data.map((objs: any) => {
            return ({
              ...objs.attributes,
              correct_answer: objs.attributes.answers.filter((object: any) => object.is_true === true).map((objs: any) => objs.answer).join(", "),
              answers: undefined,
              answers_attributes: objs.attributes.answers.map((subs: any) => {
                return ({
                  answer: subs.answer,
                  is_true:subs.is_true,
                  question_id:subs.question_id,
                  answer_id:subs.id
                })
              })
            })
          })
        }
      }));
  
    }
  }

  CreateAssessmentSetError = (responseJson:any) => {
    if(responseJson.data){
      toast.success("Assessment created successfully.")
      this.onClickGoBack()
    }
    this.setState({submitFlag:false})
  }

  EditAssessmentSetError = (responseJson:any) => {
    if(responseJson.data){
      toast.success("Assessment updated successfully.")
      this.onClickGoBack()
      if (this.state.removeAssessmentIds.length) this.singleQuestionDeleteAssessment()
    }
    this.setState({submitFlag:false})
  }

  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData("token")
    this.setState({ authToken: authToken }, () => {
      this.getAllCourses()
      const getParam = this.props.navigation.getParam("id")
      getParam && this.getSingalAssessment(getParam)
    });
  }

  onClickGoBack = () => {
    this.props.navigation.goBack()
  }

  getAllCourses = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiAllCourseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllCourseAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSingalAssessment = (idss:string) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiSingleAssessmentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singleAssessmentAPIEndPoint + idss
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmitCreateAssessment = () => {
    let newErrors:any = {};
    // Validate main fields
    Object.keys(this.state.allQuestion).forEach((keys) => {
      const error = this.validateField(keys, this.state.allQuestion[keys]);
      if (error) {
        newErrors[keys] = error;
      }
    });

    // Validate 'marks' for each question
    this.state.allQuestion.questions_attributes.forEach((question:any, index:number) => {
      Object.keys(question).forEach((keys) => {
        const error = this.validateField(keys, question[keys], index);
        if (error) {
          newErrors[`question_${index}_${keys}`] = error;
        }
      });
      // Validate answers_attributes
      question.answers_attributes.forEach((answer:any, answerIndex:number) => {
        const error = this.validateField('answer', answer.answer, index, answerIndex);
        if (error) {
          newErrors[`question_${index}_answer_${answerIndex}`] = error;
        }
      });
    });

    this.setState({errors:newErrors});
     // submit API call
    if (Object.keys(newErrors).length === 0) {
      this.onSubmitCreateAssessmentCallApi()
    }
  }

  validateField = (name:string, value:any, questionIndex?:number | any, answerIndex?:number | any) => {
    let error = '';
    switch (name) {
      case 'name':
        if (value.trim() === '') {
          error = 'Name is required.';
        }
        break;
      case 'course_id':
        if (value === '') {
          error = 'Course name is required.';
        }
        break;
      case 'passing_score':
        let returnScoreError = this.onCallScoreValidation(value)
        error = returnScoreError
        break;
      case 'retake':
        if (isNaN(value) || value === "") {
          error = 'Retake test is required.';
        }
        break;
      case 'marks':
        if (isNaN(value) || value === ""){
          error = 'Marks are required.';
        }
        break;
      case 'question':
        if (value.trim() === '') {
          error = 'Text is required.';
        }
        break;
      case 'correct_answer':
        let returnError = this.onCallValidationFuction(questionIndex,value)
        error = returnError
        break;
      case 'answer':
        let returnAnswerError = this.onCallAnswerValidation(value,questionIndex, answerIndex)
        error = returnAnswerError
        break;
      default:
        break;
    }
    return error;
  }

  onCallScoreValidation = (value:any) => {
    let error = '';

    if (isNaN(value) || value === "") {
      error = 'Score is required.';
    }
    (isNaN(value) || Number(value) >= 100) && (error = 'Passing score should be less than 100.')

    return error
  }

  onCallAnswerValidation = (value:string,questionIndex:number, answerIndex:number) => {
    let error = '';

    if(this.state.allQuestion.questions_attributes[questionIndex].question_type !== "subjective"){
      if (value.trim() === '') {
        error = 'Answer is required.';
      }
      const isAnswerUnique = this.state.allQuestion.questions_attributes[questionIndex].answers_attributes.every((answer: any, index: number) => {
        return index === answerIndex || answer.answer !== value;
      });

      (value.trim() !== '' && !isAnswerUnique) && (error = 'Please add four different answer options.')
    }

    return error

  }

  onCallValidationFuction = (questionIndex:number,value:string) => {
    let error = '';
    if (value.trim() === '') {
      if(this.state.allQuestion.questions_attributes[questionIndex].question_type !== "subjective"){
        error = 'Correct answer is required.';
      }
    } else {
      const mainType = this.state.allQuestion.questions_attributes[questionIndex].question_type
      switch (mainType) {
        case 'multiple_select':
          const selectedOptions = this.state.allQuestion.questions_attributes[questionIndex].answers_attributes.filter((answer:any) => answer.answer !== "").map((option:any) => option.answer);
          const correctOptions = value?.split(',').map((option:any) => option.trim());
          const finalvalue = correctOptions.every((option:any) => selectedOptions.includes(option));
          if (!finalvalue) {
            error = 'This Correct Answer is invalid because it does not match any of the provided options above.'
          }
          break;
        case 'single_select':
          const selectedOptionsSingle = this.state.allQuestion.questions_attributes[questionIndex].answers_attributes.filter((answer:any) => answer.answer === value);
          if(selectedOptionsSingle.length !== 1){
            error = 'Correct answer should exactly match with any one of the answer options provided.'
          }
          break;
        case 'dropdown':
          const selectedOptionsDropDown = this.state.allQuestion.questions_attributes[questionIndex].answers_attributes.filter((answer:any) => answer.answer === value);
          if(selectedOptionsDropDown.length !== 1){
            error = 'Correct answer should exactly match with any one of the answer options provided.'
          }
          break;
        default:
          break;
  
      }
    }
    return error
  }


  handleNameChange = (event:any) => {
    const { name, value } = event.target;
    const error = this.validateField(name, value);

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: error
      },
      allQuestion: {
        ...prevState.allQuestion,
        [name]: value
      }
    }));

  }

  handleQuestionChange = (index:number, event:any) => {
    const { name, value } = event.target;
    const error = this.validateField(name, value, index);

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [`question_${index}_${name}`]: error,
      },
      allQuestion: {
        ...prevState.allQuestion,
        questions_attributes: prevState.allQuestion.questions_attributes.map((question:any, isss:number) =>
        isss === index ? { ...question, [name]: value } : question
        ),
      },
    }));

  }

  deleteQuestion = (index:number, idss:any) => {
    if(idss){
      let arrayData = [...this.state.removeAssessmentIds]
      arrayData.push(idss)
      this.setState({removeAssessmentIds:arrayData})
    }
    this.setState(prevState => {
      const updatedQuestions = [...prevState.allQuestion.questions_attributes];
      updatedQuestions.splice(index, 1);
      return { allQuestion: { ...prevState.allQuestion, questions_attributes: updatedQuestions } };
    });

  }
  valueReturn = (value:string) => { 

    const rendomData = [
      { answer: "" },
      { answer: "" },
      { answer: "" },
      { answer: "" }
    ]

    return value === "dropdown" ? this.state.dropdownData : rendomData
  }

  handleTypeChange = (subIndex:any, event:any) =>{
    const { value } = event.target;
    let lstTmp = this.state.allQuestion.questions_attributes.map((objs:any, findIndex:number) => {
      if (subIndex === findIndex) {
        return ({
          marks: "",
          question_type: value,
          question: "",
          answers_attributes: value === 'subjective' ? [] : this.valueReturn(value),
          correct_answer: ""
        })
      } else {
        return objs
      }
    })
    this.setState({allQuestion:{...this.state.allQuestion,questions_attributes: lstTmp}})
    this.setState(prevState => {
      const updatedErrors = { ...prevState.errors };
        delete updatedErrors[`question_${subIndex}_marks`];
        delete updatedErrors[`question_${subIndex}_question`];
        delete updatedErrors[`question_${subIndex}_correct_answer`];
        delete updatedErrors[`question_${subIndex}_answer_0`];
        delete updatedErrors[`question_${subIndex}_answer_1`];
        delete updatedErrors[`question_${subIndex}_answer_2`];
        delete updatedErrors[`question_${subIndex}_answer_3`];

      this.setState({ errors: updatedErrors });
    })
  }

  addNewAnswerDropdown = (questionIndex: any) => {
    this.setState((prevState) => {
      const newQuestions = [...prevState.allQuestion.questions_attributes];
      newQuestions[questionIndex].answers_attributes.push({ answer: '' });
  
      return {
        allQuestion: {
          ...prevState.allQuestion,
          questions_attributes: newQuestions,
        },
      };
    });
  };

  removeAnswerDropdown = (questionIndex: any) => {
    this.setState((prevState) => {
      const newQuestions = [...prevState.allQuestion.questions_attributes];
      if (newQuestions[questionIndex].answers_attributes.length > 2) {
        newQuestions[questionIndex].answers_attributes.pop();
      }
      
      return {
        allQuestion: {
          ...prevState.allQuestion,
          questions_attributes: newQuestions,
        },
      };
    });
  };

  handleAnswerChange = (questionIndex:number, answerIndex:number, value:any)=> {
    this.setState((prevState:any) => {
      const updatedQuestions:any = [...prevState.allQuestion.questions_attributes];
      updatedQuestions[questionIndex].answers_attributes[answerIndex].answer = value;

      // Validate the answer
      const error = this.validateField('answer', value, questionIndex, answerIndex);

      if (error) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [`question_${questionIndex}_answer_${answerIndex}`]: error,
          },
        }));
      } else {
        // Clear the error if the answer is valid
        const updatedErrors = { ...prevState.errors };
        delete updatedErrors[`question_${questionIndex}_answer_${answerIndex}`];

        this.setState({ errors: updatedErrors });
      }

      return { allQuestion: {
        ...prevState.allQuestion,
        questions_attributes:updatedQuestions
      } };
    });
  }

  addQuestion = () => {
    const newQuestion:any = {
      marks: '',
      question_type: 'multiple_select',
      question: '',
      answers_attributes: [
        { answer: '' },
        { answer: '' },
        { answer: '' },
        { answer: '' },
      ],
      correct_answer: '',
    };

    let datas = {
      ...this.state.allQuestion,
      questions_attributes:[...this.state.allQuestion.questions_attributes, newQuestion]
    }

    this.setState({allQuestion:datas})
  }

  onSubmitCreateAssessmentCallApi = async() => {
    this.setState({submitFlag:true})
    
    const lastTmp = {
      ...this.state.allQuestion,
      questions_attributes : this.state.allQuestion.questions_attributes.map((object:any) => {
      if(object.question_type === "multiple_select" || object.question_type === "single_select" || object.question_type === "dropdown"){
        const correctOptions = object.correct_answer?.split(',').map((option: any) => option.trim());
        const finalObject = object.answers_attributes.map((answer:any) => {
          const finalvalue = correctOptions.includes(answer.answer);
          return ({
            ...answer,
            is_true: finalvalue
          })
        })
        return({
          ...object,
          answers_attributes:finalObject,
          correct_answer:undefined
        })
      }else{
        return({
          ...object,
          answers_attributes:[
            {
              answer:object.correct_answer,
              is_true:true
            }
          ],
          correct_answer:undefined
        })
      }
    })}
    let datas = {
      assessment: lastTmp
    };
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createApiAssessmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAssessmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(datas)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmitEditAssessment = () => {
    let newErrorsEdit:any = {};
    // Validate main fields
    Object.keys(this.state.allQuestion).forEach((keys) => {
      const errorEdit = this.validateField(keys, this.state.allQuestion[keys]);
      if (errorEdit) {
        newErrorsEdit[keys] = errorEdit;
      }
    });

    // Validate 'marks' for each question
    this.state.allQuestion.questions_attributes.forEach((question:any, index:number) => {
      Object.keys(question).forEach((keys) => {
        const errorEdit = this.validateField(keys, question[keys], index);
        if (errorEdit) {
          newErrorsEdit[`question_${index}_${keys}`] = errorEdit;
        }
      });
      // Validate answers_attributes
      question.answers_attributes.forEach((answer:any, answerIndex:number) => {
        const errorEdit = this.validateField('answer', answer.answer, index, answerIndex);
        if (errorEdit) {
          newErrorsEdit[`question_${index}_answer_${answerIndex}`] = errorEdit;
        }
      });
    });

    this.setState({errors:newErrorsEdit});
    
     // submit API call
    if (Object.keys(newErrorsEdit).length === 0) {
      this.onSubmitEditAssessmentCallApi()
    }
  }

  convertData = (originalData: any) => {
    const { name, course_id, passing_score, retake, questions_attributes } = originalData;

    // Transform assessment data
    const assessment = {
      name,
      course_id,
      passing_score: parseInt(passing_score), // Convert passing_score to an integer
      retake,
    };
    let transformedQuestion: any = []
    let answers_attributes_transformed:any = []
    // Transform questions and answers data
    questions_attributes.forEach((element: any) => {
      
      
      const { id, question, question_type, marks, correct_answer, answers_attributes } = element;
      if(id){
        const transformedQuestionObject = {
          question_id: id,
          question,
          marks,
          question_type,
        };
  
        transformedQuestion.push(transformedQuestionObject)
  
        answers_attributes.forEach((answers: any) => {
          const { answer_id, question_id, answer } = answers
  
          const correctOptions = correct_answer?.split(',').map((option: any) => option.trim());
          const finalvalue = correctOptions.includes(answer);
  
          const answers_attributes_transformedObject = {
            answer_id,
            question_id,
            answer: question_type === "subjective" ? correct_answer : answer,
            is_true: question_type === "subjective" ? true : finalvalue
          }
  
          answers_attributes_transformed.push(answers_attributes_transformedObject)
        });
      }else{
        this.onSubmitCreateSingleAssessmentCallApi(element)
      }
    });
    // // Combine transformed data into the desired format
    const transformedData = {
      assessment,
      questions_attributes: transformedQuestion,
      answers_attributes: answers_attributes_transformed,
    };

    return transformedData;
  };

  onSubmitEditAssessmentCallApi = async() => {
    this.setState({submitFlag:true})
    const transformedData = this.convertData(this.state.allQuestion);
    const getParam = this.props.navigation.getParam("id")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editApiAssessmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editAssessmentAPIEndPoint + getParam
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(transformedData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmitCreateSingleAssessmentCallApi = async(data:any) => {
    const lastTmpData = {
      assessment_id: this.props.navigation.getParam("id"),
      question: {
        ...data,
        correct_answer: undefined,
        answers_attributes: data.answers_attributes.map((objs: any) => {
          const correctOptions = data.correct_answer?.split(',').map((option: any) => option.trim());
          const finalvalue = correctOptions.includes(objs.answer);
          return ({
            ...objs,
            is_true: finalvalue
          })
        })
      }
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.singleCreateApiAssessmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singleQuestionCreateAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(lastTmpData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  deleteAssessment = async() => {
    this.setState({submitFlag:true})
    const idss = this.props.navigation.getParam("id")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiAssessmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAssessmentAPIEndPoint + idss
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickCloseDelete = () => {
    this.setState({onDeleteOpen:false})
  }

  onClickOpenDelete = () => {
    this.setState({onDeleteOpen:true})
  }

  singleQuestionDeleteAssessment = async() => {
    const lessonHeader = {
      token: await getStorageData("token")
    };

    const requestMessagelesson = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteQuestionApiAssessmentApiCallId = requestMessagelesson.messageId;

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(lessonHeader)
    );

    let formDataDelete = new FormData();
    this.state.removeAssessmentIds.forEach((element:string) => {
      formDataDelete.append("question_id", element)
    });

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataDelete
    );

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAssessmentQuestionAPIEndPoint
    );

    requestMessagelesson.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteApiMethod
    );

    runEngine.sendMessage(requestMessagelesson.id, requestMessagelesson);

  }
  // Customizable Area End
}
