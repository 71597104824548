Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.AllCategoryAPIEndPoint = "bx_block_profile/courses";
exports.AllParticularCategoryAPIEndPoint = "bx_block_profile/courses/"
exports.AllCategorySessionAPIEndPoint = "bx_block_profile/lessons?course_id="
exports.AllCategorySearchAPIEndPoint = "/bx_block_profile/course/search"
exports.submitReviewAPIEndPoint = "bx_block_profile/reviews"
exports.studentSurveysAPIEndPoin="bx_block_surveys2/student_surveys"
exports.studentSurveysPostAPIEndPoin="bx_block_surveys2/student_surveys"
exports.setWatchedSessionAPIEnd= "bx_block_profile/lessons/"

exports.AllParticularCategoryAPIEndPointGuest = "bx_block_profile/courses/show_course?id="
exports.AllCategorySearchAPIEndPointGuest = "bx_block_profile/courses/guest_search"
exports.getAssesessmentByCourse = "bx_block_assessmenttest/assessments/show_by_course_id?course_id="

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"


// Customizable Area End