import React from "react";

// Customizable Area Start
import "../assets/css/AboutRaffles.css"
import { Paper } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AboutRafflesController, {
    Props
} from "./AboutRafflesController";
import GuestNavigationMenu from "../../dashboard/src/GuestNavigationMenu.web";
const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

interface RafflesInfo {
    id: string;
    type: string;
    attributes: {
        description: string;
        image: {
            url: string;
        };
    };
}
// Customizable Area End



export default class GuestAboutRaffles extends AboutRafflesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        // Customizable Area Start
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <GuestNavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="About raffles"
                >
                    <MainContainer>
                        <div className="content-wrapper about-content-raffles">
                            <div className="raffles-about-block">
                                <h6>About Us</h6> 
                                {
                                    this.state.aboutData.map((item: RafflesInfo, index: number) => {
                                        return (item.attributes && <div key={index} className="raffles-about-content">
                                            <div className="raffles-about-content-image">
                                                <img src={baseURL + item.attributes?.image?.url} alt="About Raffles" />
                                            </div>
                                            <div className="raffles-about-inner-content" dangerouslySetInnerHTML={{ __html: item.attributes?.description }} />
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    </MainContainer>
                </GuestNavigationMenu>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const MainContainer = styled(Paper)({
    width: "100%", 
    overflow: "hidden",
    "& .content-wrapper":{
        height: 'calc(100vh - 89px)'
    }
})
// Customizable Area End
