import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl:any;
  checked:boolean;
  checked1:boolean;
  result: any;
  getAnswer:boolean;
  authToken:string;
  assessmentResultList:[];
  isLoading:boolean;
  serchAssementValue:string;
  assessmentSubjective:any;
  errors:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmenttestResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiResultAssessementApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl:null,
      checked:false,
      checked1:false,
      result: 'Pass',
      getAnswer:false,
      authToken:"",
      assessmentResultList:[],
      isLoading:false,
      serchAssementValue:"",
      assessmentSubjective:[
        {
          question:"Enter any five Prime Number ?",
          answer:"2,3,5,7,9",
          marks:""
        }
      ],
      errors:{}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.responseData(apiRequestCallId, responseJson)
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  responseData = (apiRequestCallId:any, responseJson:any) => {
    (apiRequestCallId === this.getApiResultAssessementApiCallId && responseJson.data) && this.onCallFunctionList(responseJson)
  }

  onCallFunctionList = (responseJson:any) => {
    let arrayOfObjects = responseJson.data.sort((asss:any, bsss:any) => bsss.id - asss.id);
    this.setState({assessmentResultList:arrayOfObjects,isLoading:false})
  }

  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData("token")
    this.setState({ authToken: authToken }, () => {
      this.getApiAssessementResult()
    });
  }

  handleClick = (event:any) => {
    this.setState({ anchorEl : event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl : null , checked : false , checked1 : false });
  };

  handleChange = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleChange11 = (event:any) => {
    this.setState({ checked1: !this.state.checked1 });
  };

  handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ result: event.target.value as string });
  };

  onClickGoBack = () => {
    this.props.navigation.goBack()
  }

  getApiAssessementResult = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiResultAssessementApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AllAssessmentAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  serchAssementList = (event:any) => {
    this.setState({serchAssementValue: event.target.value})
  }

  redirectCreateAssessment = () => {
    this.props.navigation.navigate("AssessmenttestCreate")
  }

  validateField = (name:string, value:any, questionIndex?:number | any, answerIndex?:number | any) => {
    let error = '';
    if(name === 'marks'){
      if (isNaN(value) || value === ""){
        error = 'Marks are required.';
      }
    }
    return error;
  }
  

  onChangeMarks = (event:any, index:number) => {
    let errors:any = {};
    const error = this.validateField(event.target.name, event.target.value, index);
    if (error) {
      errors[`question_${index}_${event.target.name}`] = error;
    }
    this.setState({errors:errors});
    let lstTmp = [...this.state.assessmentSubjective];
    lstTmp[index] = { ...lstTmp[index], [event.target.name]: event.target.value };
    this.setState({assessmentSubjective:lstTmp})
  }

  onSubmitSubjectiveAssessment = () => {
    let newErrors:any = {};
    // Validate 'marks' for each question
    this.state.assessmentSubjective.forEach((question:any, index:number) => {
      Object.keys(question).forEach((keys) => {
        const error = this.validateField(keys, question[keys], index);
        if (error) {
          newErrors[`question_${index}_${keys}`] = error;
        }
      });
    });

    this.setState({errors:newErrors});
    // submit API call
    if (Object.keys(newErrors).length === 0) {
      this.setState({errors:{}});
    }
  }

  resulListtarray = () => {
    if (this.state.serchAssementValue.length) {
      return this.state.assessmentResultList.filter((xdata: any) => (xdata.attributes.course.course_name).toLowerCase().includes(this.state.serchAssementValue.toLowerCase()))
    } else {
      return this.state.assessmentResultList
    }
  }

  onclickNavigateEdit = (idss:unknown) => {
    this.props.navigation.navigate("AssessmenttestEdit",{id:idss})
  }

  onclickNavigateMarks = () => {
    this.props.navigation.navigate('AssessmenttestMarks')
  }

  // Customizable Area End
}
