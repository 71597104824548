import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sessionImage } from "./assets";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

// Customizable Area Start
interface EditSessionData {
  course_id: string | number;
  sessionImage: string; // Assuming sessionImage is a URL pointing to the image
  subject: string;
  date: string;
  startTime: string;
  endTime: string;
  showToStudent: boolean;
  liveLocationAddress: string;
}
export interface InitialValues {
  courseImage: string | null | Blob | File;
  subject: string;
  livelocation: string;
  date: string;
  startTime: string;
  endTime: string;
  showtostudent: boolean;
  course_id: string;
}

export interface LiveSession {
  id: string;
  type: string;
  attributes: {
      subject: string;
      date: string;
      show_live_address: boolean | null;
      comment: string;
      start_url: string | null;
      join_url: string | null;
      zoom_id: string | null;
      created_at: string;
      updated_at: string;
      time_to: string;
      time_from: string;
      account: {
          id: number;
          first_name: string;
          last_name: string;
          full_phone_number: string;
          country_code: string | null;
          phone_number: string | null;
          email: string;
          activated: boolean;
          device_id: string | null;
          unique_auth_id: string | null;
          password_digest: string;
          created_at: string;
          updated_at: string;
          user_name: string | null;
          platform: string | null;
          user_type: string | null;
          app_language_id: string | null;
          last_visit_at: string | null;
          is_blacklisted: boolean;
          suspend_until: string | null;
          status: string;
          role_id: number;
          stripe_id: string | null;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          full_name: string | null;
          gender: string | null;
          date_of_birth: string;
          age: number | null;
          middle_name: string;
          qualification: string;
          country: string | null;
          city: string | null;
          occupation: string | null;
          reset_password_token: string | null;
          reset_password_sent_at: string;
          department: string;
          major: string;
          experience: string;
          biography: string;
          automatic_reminder: boolean;
      };
      course: {
          id: number;
          course_name: string;
          created_at: string;
          updated_at: string;
          title: string;
          information: string;
          description: string | null;
          profile_id: number;
          category_id: number;
          language: string;
          level: string | null;
          price: number;
          course_type: string;
          no_of_lessons: number | null;
          total_duration_of_lessons: number | null;
      };
      thumbnail: string;
  };
}

interface Lesson {
  id: string;
  type: string;
  attributes: {
      title: string | null;
      description: string;
      duration: string | null;
      lesson_video: {
          url: string;
      };
      lesson_attachment: {
          url: string;
      } | null;
      video_subtitle: string | null;
      lesson_image: {
          url: string;
      };
  };
}

export interface Course {
  id: string;
  type: string;
  attributes: {
      course_name: string;
      title: string;
      information: string;
      description: string;
      profile_id: number;
      category_id: number;
      language: string;
      level: string;
      price: number;
      course_type: string;
      no_of_lessons: number;
      total_duration_of_lessons: number;
      lessons: {
          data: Lesson[];
      };
      course_image: {
          url: string;
      };
      purchased: null;
      average_rating: number;
      lecturer: string;
  };
}

interface DeleteResponse {
  message: string;
}

interface ErrorData {
      [keys: string]: string[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  submitFlag:boolean;
  onDeleteOpen:boolean;
  editSessionData:EditSessionData;
  isLoading:boolean;
  authToken:string;
  courseList:Course[];
  sessionList:LiveSession[];
  searchSession:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LecturerLiveSessionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createLiveSessionsApiCallId:string = "";
  editLiveSessionsApiCallId:string = "";
  deleteLiveSessionsApiCallId:string = "";
  getCourseListApiCallId:string = "";
  getSessionListApiCallId:string = "";
  getSingleSessionListApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      submitFlag:false,
      onDeleteOpen:false,
      editSessionData:{
        sessionImage:sessionImage,
        subject:"Live session Modern Art and Ideas",
        date:"",
        startTime:"",
        endTime:"",
        showToStudent:false,
        liveLocationAddress:"",
        course_id:""
      },
      isLoading:false,
      authToken:"",
      courseList:[],
      sessionList:[],
      searchSession:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const getParams = this.props.navigation.getParam("id")
    this.getAllCourseList();
    if(getParams){
      this.getSingleSession(getParams)
    }else{
      this.getAllSessionList();
    }
  }

  onClickRedirectCreateLesson = () => {
    this.props.navigation.navigate("LecturerLiveSessionsCreate")
  }

  serchSessionList = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchSession: event.target.value})
  } 

  getAllSessionList = async() => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSessionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listAllSessionAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  getAllCourseList = async() => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listAllCoursesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  onEditSubmit = async(values:InitialValues) => {
    this.setState({submitFlag:true})
    const getParams = this.props.navigation.getParam("id")
    const header = {
      redirect: 'follow',
      token: await getStorageData('token')
    }; 

    const formDataEdit = new FormData();
    formDataEdit.append("live_session[subject]", values.subject)
    formDataEdit.append("live_session[date]", values.date)
    formDataEdit.append("live_session[time_to]", moment(values.endTime, 'HH:mm').format('hh:mm A'))
    formDataEdit.append("live_session[time_from]", moment(values.startTime, 'HH:mm').format('hh:mm A'))
    formDataEdit.append("live_session[show_live_address]", String(values.showtostudent))
    formDataEdit.append("live_session[comment]", values.livelocation)
    formDataEdit.append("live_session[course_id]", String(values.course_id))
    if((values.courseImage as File).name){
      formDataEdit.append("live_session[thumbnail]", values.courseImage as File)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.editLiveSessionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataEdit
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editLiveSessionsApiEndPoint + getParams
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.examplePutAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmit = async(values:InitialValues) => {
    this.setState({submitFlag:true})

    const header = {
      redirect: 'follow',
      token: await getStorageData('token')
    }; 
    
    const formData = new FormData();
    formData.append("live_session[subject]", values.subject)
    formData.append("live_session[date]", values.date)
    formData.append("live_session[time_to]", moment(values.endTime, 'HH:mm').format('hh:mm A'))
    formData.append("live_session[time_from]", moment(values.startTime, 'HH:mm').format('hh:mm A'))
    formData.append("live_session[show_live_address]", String(values.showtostudent))
    formData.append("live_session[comment]", values.livelocation)
    formData.append("live_session[thumbnail]", values.courseImage as File)
    formData.append("live_session[course_id]", String(values.course_id))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.createLiveSessionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createLiveSessionsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  onClickGoBackRedirect = () => {
    this.props.navigation.goBack()
  }

  onClickOpenDelete = () => {
    this.setState({onDeleteOpen:true})
  }

  onClickCloseDelete = () => {
    this.setState({onDeleteOpen:false})
  }

  onClickRedirectEdit = (idss:string) =>{
    this.props.navigation.navigate("LecturerLiveSessionsEdit",{id:idss})
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    (this.createLiveSessionsApiCallId === apiRequestCallId && responseJson) && this.handleCreateSessionResponce(responseJson);
    (this.editLiveSessionsApiCallId === apiRequestCallId && responseJson) && this.handleEditSessionResponce(responseJson);
    (this.getCourseListApiCallId === apiRequestCallId && responseJson.data) && this.setState({courseList:responseJson.data});
    (this.getSessionListApiCallId === apiRequestCallId && responseJson.data) && this.getResopence(responseJson);
    (this.deleteLiveSessionsApiCallId === apiRequestCallId && responseJson) && this.getDeleteLiveSessionsResopence(responseJson);
    (this.getSingleSessionListApiCallId === apiRequestCallId && responseJson.data) && this.findBySessionData(responseJson);
    
    // Customizable Area End
  }

  getDeleteLiveSessionsResopence = (responseJson:DeleteResponse) => {
    if(responseJson.message === "Live session deleted successfully"){
      toast.success("Session deleted successfully.")
      this.onClickCloseDelete()
      this.onClickGoBackRedirect()
    }
    this.setState({submitFlag:false})
  }

  getResopence = (responseJson:{data:LiveSession[]}) => {
    this.setState({sessionList:responseJson.data, isLoading:false});
  }

  handleCreateSessionResponce = (responseJson:{errors:ErrorData}) => {
    if(responseJson.errors){
      Object.keys(responseJson.errors).forEach((keys) => {
        toast.error(responseJson.errors[keys].join(", "))
      });
    }else{
      toast.success("Session created successfully.")
      this.onClickGoBackRedirect()
    }
    this.setState({submitFlag:false})
  }

  handleEditSessionResponce = (responseJson:{errors:ErrorData}) => {
    if(responseJson.errors){
      Object.keys(responseJson.errors).forEach((keys) => {
        toast.error(responseJson.errors[keys].join(", "))
      });
    }else{
      toast.success("Session updated successfully.")
      this.onClickGoBackRedirect()
    }
    this.setState({submitFlag:false})
  }

  findBySessionData = (responseJson:{data:LiveSession}) => {
    const {attributes} = responseJson.data
    const editSessionData = {
      sessionImage:attributes.thumbnail,
      subject: attributes.subject || "",
      date:attributes.date,
      startTime:moment(attributes.time_from, 'h:mm A').format('HH:mm') || "",
      endTime:moment(attributes.time_to, 'h:mm A').format('HH:mm') || "",
      showToStudent:attributes.show_live_address || false,
      liveLocationAddress:attributes.comment || "",
      course_id:attributes.course.id || ""
    }
    this.setState({editSessionData:editSessionData,isLoading:false})
  }

  onDelete = async() => {
    this.setState({submitFlag:true})
    const getParams = this.props.navigation.getParam("id")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteLiveSessionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteLiveSessionsApiEndPoint + getParams
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  getSingleSession = async(getParams:string) => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleSessionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listSingleSessionAPIEndPoint + getParams
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  resulListtarray = () => {
    if(this.state.searchSession.length){
      return this.state.sessionList.filter((xsss:LiveSession) => (xsss.attributes.subject).toLowerCase().includes(this.state.searchSession.toLowerCase()))
    }else{
      return this.state.sessionList
    }
  }


  onFileChange = (event:React.ChangeEvent<HTMLInputElement>, setFieldValue:(field: keyof InitialValues, value: File) => void) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFieldValue("courseImage", file);
    }
  }

  onDateFomateChange = (date:string) =>{
    const dates = moment(date).format('DD MMM YYYY')
    return dates
  }

  onClickRedirectURL = (URLS:string|null, setCourseId: number) => {
    window.open(URLS as string, '_blank');
  }

  // Customizable Area End
}
